import {
  Controller
} from "stimulus"
import {
  Swiper
} from "./mixins/swiper";
import {
  lazyLoad
} from "./mixins/lazyload";
export default class extends Controller {
  connect() {
    Swiper(this)
    this.loadSwiper()
    lazyLoad(this)
    this.loadLazy()
  }

  disconnect() {
    this.destroySwiper()
  }
}