import {
    Controller
} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["status", "textArea"]
    copyTextArea() {
      navigator.clipboard.writeText(this.textAreaTarget.value).then(function () {
        this.statusTarget.classList.remove('opacity-0')
        setTimeout(function () {
          this.statusTarget.classList.add('opacity-0')
        }.bind(this), 1500)
      }.bind(this))
    }

    copyLink() {
        let url = document.location.href

        navigator.clipboard.writeText(url).then(function(){
            this.statusTarget.classList.remove('opacity-0')
            setTimeout(function(){
                
                this.statusTarget.classList.add('opacity-0')
            }.bind(this), 1500)
        }.bind(this))
    }
}