import {
  Controller
} from "stimulus"
  window.slides = [];
  window.slide_index = 0;
export default class extends Controller {
  connect() {
    slides.push(document.querySelector('.slides').innerHTML);
    setInterval(function () {
      this.load_slide();
    }.bind(this), 30000)

    document.onkeydown = function (e) {
      switch (e.key) {
        case 'ArrowRight':
          if (typeof slides[slide_index + 1] === 'undefined') {
            slide_index += 1;
            this.load_slide();
          } else {
            slide_index += 1;
            document.querySelector('.slides').innerHTML = slides[slide_index];
          }
          break;
        case 'ArrowLeft':
          if (typeof slides[slide_index - 1] !== 'undefined') {
            slide_index -= 1;
          } else {
            slide_index = 0;
          }
          document.querySelector('.slides').innerHTML = slides[slide_index];
          break;
      }
    }.bind(this);
  }

  disconnect() {}

  load_slide(){
    document.querySelector('.slide-loader').classList.remove('hidden')
    App.vendor.rails.ajax({
      type: "GET",
      url: window.location.pathname + `/slide.js?_=${new Date().getTime()}`,
      success: function (response) {
        document.querySelector('.slide-loader').classList.add('hidden')
      },
      error: function (response) {}
    });
  }
}

