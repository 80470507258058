import {
  Controller
} from "stimulus"
export default class extends Controller {
  static targets = ["unitOfMeasurePrice", "submit"]


  connect() {
  }

  disconnect() {}

  disableAddToCart(e){
    App.vendor.rails.disableElement(e.target)
    e.target.closest('form').style.opacity = 0.6
    e.target.closest('form').dispatchEvent(new Event('submit', {
      bubbles: true
    }));
  }

  disableRemove(e){
    console.log(e.target.closest('form'))
    App.vendor.rails.disableElement(e.target)
    e.target.closest('form').style.opacity = 0.6
  }

  updateUnitOfMeasurePrice(e){
    if(this.hasUnitOfMeasurePriceTarget){
      if(e.target.selectedOptions[0].getAttribute('data-price') == 'false'){
        this.unitOfMeasurePriceTarget.innerHTML = ''
      }else{
        console.log(parseInt(e.target.selectedOptions[0].getAttribute('data-price')))
        this.unitOfMeasurePriceTarget.innerHTML = e.target.selectedOptions[0].getAttribute('data-price')
      }
    }
  }
}