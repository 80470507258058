import {
  Controller
} from "stimulus"
export default class extends Controller {
  connect(){
    setTimeout(() => {
      this.bubbleCheck()
    }, 2000)
    window.addEventListener('scroll', this.bubbleCheck.bind(this));
  }
  bubbleCheck(){
    const element = document.querySelector('.woot-widget-bubble');
    if(element){
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
        element.style.bottom = '80px'
      } else {
        element.style.bottom = '20px'
      }
    }
  }
  disconnect(){
    const element = document.querySelector('.woot-widget-bubble');
    if(element){
      element.style.bottom = '20px'
    }
    window.removeEventListener('scroll', this.bubbleCheck.bind(this));
  }
  open(e){
    e.preventDefault()
    window.$chatwoot.toggle('open')
  }
  close(e){
    e.preventDefault()
    window.$chatwoot.toggle('close')
  }

  toggle(e){
    e.preventDefault()
    window.$chatwoot.toggle()
  }
}