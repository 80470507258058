import App from '../../application';

export const swal = controller => {
  Object.assign(controller, {
    loadSwal(e) {
      App.utils.flashes.set = function (flashes) {
        App.store.flashes = flashes
        App.utils.flashes.notify(App.store.flashes)
      }
      App.utils.flashes.notify = function (flashes) {
        import('sweetalert2/dist/sweetalert2.js').then(function (Swal) {
          flashes.forEach(message => {
            var type = message[0];
            try {
              // we always have a message type, but sometimes in the case of( spree order complete = true) this will fail, let's just catch the error and not bomb out
              var notification = message[1].replace(/google_oauth2/gi, 'Google').replace(/facebook/gi, 'Facebook')
              switch (type) {
                case 'error':
                  var container_background = 'bg-primary';
                  break;
                default:
                  var container_background = 'bg-secondary';
              }
              Swal.default.fire({
                html: notification,
                // toast: true,
                showConfirmButton: true,
                customClass: {
                  container: 'w-full sm:w-1/2 slide-up container mx-auto fixed left-0 right-0 bottom-0 p-4 m-4 shadow-lg rounded-md z-50 ' + container_background,
                  popup: 'flex justify-between content-center items-center',
                  // header: 'header-class',
                  // title: 'dfsfsdfsdfasdffadf',
                  // closeButton: 'close-button-class',
                  // icon: 'icon-class',
                  // image: 'image-class',
                  content: 'lead-h2 text-white',
                  // input: 'input-class',
                  actions: 'self-center ml-4',
                  confirmButton: 'rounded-sm bg-white text-secondary px-4 py-2 text-sm shadow-md',
                  // cancelButton: 'cancel-button-class',
                  // footer: 'footer-class'
                }
              })
            } catch (error) {
              console.error(error);
            }
          })
          App.store.flashes = [];
        });
      }
      var waiting = false;
      App.utils.flashes.inline = function (dom_id, html, classes, timeout, wait_time) {
        if (!waiting) { // If we're not waiting
          import('sweetalert2/dist/sweetalert2.js').then(function (Swal) {
          Swal.default.fire({
            target: dom_id,
            html: html,
            toast: true,
            showConfirmButton: false,
            timer: timeout,
            timerProgressBar: true,
            customClass: {
              container: classes,
              content: 'w-full'
            }
          })
        })
          waiting = true; // Prevent future invocations
          setTimeout(function () { // After a period of time
            waiting = false; // And allow future invocations
          }, wait_time || 5000);
        }
      }
    },
  });
};