import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["filter", "filterContainer"]

  connect() {}

  disconnect() {}

  toggleFilter(){
    if (this.hasFilterContainerTarget) {
      this.filterContainerTarget.classList.toggle('hidden');
    }
    if (this.hasFilterTarget) {
      this.filterTarget.classList.toggle('slide-in');
    }
  }
}
