import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["addressValidation","correctedAddressMap", "modifyAddressContainer", "originalAddress", "correctedAddress", "originalAddressValue", "correctedAddressValue", "", "formTitle", "addressContainer", "submit", "autocomplete", "address_type", "address2", "city", "state", "zipcode"]
  connect() {
    this.API_KEY = "AIzaSyCAdFsvyxsxE2gn-rUy_F5hnGh6N6rU6p8"
    Spree.Checkout = []
    this.autocomplete = null
    this.map = null
    this.placeService = null
    this.placeChanged = this.getAddress.bind(this)
    this.timer = null
    this.validatedAddress = false
    this.originalAddress = {
      address1: '',
      city: '',
      state: '',
      zipcode: ''
    }
    this.correctedAddress = {
      address1: '',
      city: '',
      state: '',
      zipcode: ''
    }
    const options = {
      componentRestrictions: {
        country: ["us"]
      },
      fields: ["address_components", "geometry"],
      types: ["street_address", "street_number", "premise"],
    };


    try {
      if (typeof google == "undefined") {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.API_KEY}&libraries=places,geometry`;
        document.body.appendChild(script);
        script.onload = function () {
          this.autocomplete = new google.maps.places.Autocomplete(
            this.autocompleteTarget,
            options
          );
          this.autocompleteService =
            new google.maps.places.AutocompleteService();
          this.autocomplete.addListener("place_changed", this.placeChanged);

          if (this.hasCorrectedAddressMapTarget) {
            this.map = new google.maps.Map(this.correctedAddressMapTarget, {
              zoom: 15,
              center: {
                lat: 0,
                lng: 0,
              },
              mapId: "IDfa2df21bfbfac28e",
              disableDefaultUI: true,
            });

            this.placeService = new google.maps.places.PlacesService(this.map);
          }
        }.bind(this);
      } else {
        this.autocomplete = new google.maps.places.Autocomplete(
          this.autocompleteTarget,
          options
        );
        this.autocompleteService = new google.maps.places.AutocompleteService();
        if (this.hasCorrectedAddressMapTarget) {
          this.map = new google.maps.Map(this.correctedAddressMapTarget, {
            zoom: 15,
            center: {
              lat: 0,
              lng: 0,
            },
            mapId: "IDfa2df21bfbfac28e",
            disableDefaultUI: true,
          });
          this.placeService = new google.maps.places.PlacesService(this.map);
        }

        this.autocomplete.addListener("place_changed", this.placeChanged);
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleAddressType(e) {
    if (e.target.value === 'pickup') {
      this.addressContainerTarget.classList.add('hidden')
      this.autocompleteTarget.required = false
      this.autocompleteTarget.value = 'Dummy Address'
      this.formTitleTarget.innerHTML = 'Contact Information'
    } else {
      this.addressContainerTarget.classList.remove('hidden')
      this.autocompleteTarget.required = true
      this.autocompleteTarget.value = null
      this.formTitleTarget.innerHTML = 'Delivery Information'
    }
  }

  async validateAddress() {
    const addr = `${this.autocompleteTarget.value}, ${this.cityTarget.value} ${this.stateTarget.selectedOptions[0].text} ${this.zipcodeTarget.value}`
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${this.API_KEY}`;
    const options = {
      'method': 'post',
      'contentType': 'application/json',
      'body': JSON.stringify({
        "address": {
          "addressLines": [addr]
        },
        "enableUspsCass": true
      })
    };
    this.modifyAddressContainerTarget.classList.add('hidden')
    this.addressValidationTarget.classList.add('hidden')
    const response = await fetch(url, options);
    const validatedAddress = await response.json();
    const place = validatedAddress.result

      this.autocompleteService.getQueryPredictions({input: place.address.formattedAddress}, async function(predictions, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {

          this.correctedAddress = await this.getPredictionDetails(predictions[0].place_id).then(function (place) {
            this.createMarker(place);
            return this.update_address(place)
          }.bind(this))

          if(this.correctedAddress.address1.length == 0){
            this.addressNotValidated()
          }else{
            this.correctedAddressTarget.innerHTML = this.correctedAddress.full_address
            this.correctedAddressValueTarget.checked = false
            this.map.setCenter(this.correctedAddress.geometry);
  
            if (this.areAddressesEqual() && !this.validatedAddress) {
              this.modifyAddressContainerTarget.classList.add('hidden')
              this.autocompleteTarget.closest('form').requestSubmit();
            } else {
              this.modifyAddressContainerTarget.classList.remove('hidden')
            }

          }
        }else{
          this.addressNotValidated()
        };
      }.bind(this))



  }

  addressNotValidated() {
    this.validatedAddress = false
    this.addressValidationTarget.classList.remove('hidden')
    window.scrollTo(0, 0);
    this.autocompleteTarget.focus()
  }

  areAddressesEqual() {
    return this.correctedAddress.address1 == this.originalAddress.address1 && this.correctedAddress.city == this.originalAddress.city && this.correctedAddress.state == this.originalAddress.state && this.correctedAddress.zipcode == this.originalAddress.zipcode
  }
  async createMarker(place) {
    if (!place.geometry || !place.geometry.location) return;
    let map = this.map
    const {
      AdvancedMarkerElement
    } = await google.maps.importLibrary("marker");

    new AdvancedMarkerElement({
      map,
      position: place.geometry.location,
    });
  }
  handleModifiedAddress(e) {
    if (e.target.value == 'corrected'){
      this.originalAddress = this.correctedAddress
      this.fillInAddress(this.correctedAddress)
      this.modifyAddressContainerTarget.classList.add('hidden')
      this.validatedAddress = true
    }else{
      this.fillInAddress(this.originalAddress)
    }
  }
  
  handleSubmit(e) {
    try {
      e.preventDefault({bubbles: true});
      if (this.validatedAddress || !this.hasModifyAddressContainerTarget) {
        e.target.closest('form').requestSubmit();
      } else {
        this.validateAddress();
      }
      this.updateOriginalAddress()
    } catch (error) {
      console.error(error)
      e.target.closest('form').requestSubmit();
    }
  }
  handleFormChange(e) {
      this.validatedAddress = false
  }
  changeAddress(e) {
    e.preventDefault()
    this.autocompleteTarget.focus()
    this.addressValidationTarget.classList.add('hidden')
  }
  fillInAddress(address) {
    const $options = Array.from(this.stateTarget.options);
    const optionToSelect = $options.find(item => item.text === address.state);
    this.autocompleteTarget.value = address.address1;
    this.cityTarget.value = address.city;
    this.zipcodeTarget.value = address.zipcode;
    optionToSelect.selected = true
  }
  async getAddress() {
    // Get the place details from the autocomplete object.
    
    this.place = this.autocomplete.getPlace();
    
    
    if (!this.place || this.place.address_components === undefined) {
      return
      
    } else {
        this.originalAddress = this.update_address(this.place)
        this.address2Target.focus();  
        this.fillInAddress(this.originalAddress)
        if (this.autocompleteTarget.value == "" || this.cityTarget.value == "" || this.stateTarget.value == "" || this.zipcodeTarget.value == "") {
          return;
          }
          this.validatedAddress = true
    }
    if (!this.hasModifyAddressContainerTarget) {
      this.validatedAddress = true
    } else {
      this.modifyAddressContainerTarget.classList.add('hidden')
    }
  }
  disconnect() {
    google.maps.event.removeEventListener("place_changed", this.fillInAddress());
  }

  updateOriginalAddress() {
    this.originalAddress = {
      address1: this.autocompleteTarget.value,
      city: this.cityTarget.value,
      state: this.stateTarget.selectedOptions[0].text,
      zipcode: this.zipcodeTarget.value
    }

  }

  update_address(place) {
    let address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.long_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case "locality":
          city = component.long_name;
          break;

        case "administrative_area_level_1": {
          const $options = Array.from(this.stateTarget.options);
          const optionToSelect = $options.find(item => item.text === component.long_name);
          if(optionToSelect){
            state = optionToSelect.text;
            optionToSelect.selected = true;
          }
          break;
        }

        case "country":
          break;
      }
    }

      return {
        address1: address1,
        city: city,
        state: state,
        zipcode: postcode,
        full_address: `${address1} ${city}, ${state} ${postcode}`,
        geometry: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }
      }
  }

  async getPredictionDetails(placeId) {
    let self = this
    let promise = new Promise((resolve, reject) => {
      self.placeService.getDetails({
        placeId: placeId
      }, function (result, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          reject(status);
        }
      }.bind(this));
    });
    return promise
  }
  updateState(region) {
    var self = this;
    var countryId = this.getCountryId(region)
    if (countryId != null) {
      if (Spree.Checkout[countryId] == null) {
        App.vendor.rails.ajax({
          type: "GET",
          url: Spree.pathFor('/api/v2/storefront/countries/' + countryId + '?include=states'),
          dataType: 'script',
          headers: {
            'X-CSRF-Token': App.vendor.rails.csrfToken(),
            'X-Requested-With': 'XMLHttpRequest'
          },
          success: function (data) {
            var json = data.included;
            var xStates = []

            for (var i = 0; i < json.length; i++) {
              var obj = json[i];
              xStates.push({
                'id': obj.id,
                'name': obj.attributes.name
              })
            }


            Spree.Checkout[countryId] = {
              states: xStates,
              states_required: data.data.attributes.states_required,
              zipcode_required: data.data.attributes.zipcode_required
            }
            self.fillStates(Spree.Checkout[countryId], region)

          }
        });



      } else {
        self.fillStates(Spree.Checkout[countryId], region)
      }
    }
  }

  fillStates(data, region) {
    var selected, statesWithBlank
    var statesRequired = !!data.states_required
    var states = data.states
    var statePara = document.getElementById(region + 'state')
    var stateSelect = statePara.querySelector('select')
    var stateInput = statePara.querySelector('input')
    // var stateSpanRequired = statePara.querySelector('#state-required')
    if (states.length > 0) {
      selected = parseInt(stateSelect.value)
      stateSelect.innerHTML = ''
      statesWithBlank = [{
        name: 'Please Select',
        id: ''
      }].concat(states)
      statesWithBlank.forEach(function (state) {
        var opt = document.createElement('option')
        opt.setAttribute('value', state.id)
        opt.innerHTML = state.name
        if (selected === state.id) {
          opt.selected = true
        }
        stateSelect.appendChild(opt)
      })
      stateSelect.disabled = false
      stateSelect.style.display = "block"

      stateInput.style.display = "none"
      stateInput.disabled = true
      statePara.style.display = "block"
      // stateSpanRequired.style.display = "block"

      if (statesRequired) {
        stateSelect.classList.add('required')
      }
      stateSelect.classList.remove('hidden')
      stateInput.classList.remove('required')
    } else {
      stateSelect.style.display = "none"
      stateSelect.disabled = true
      stateInput.style.display = "block"

      if (statesRequired) {
        stateSpanRequired.style.display = "block"
        stateInput.classList.add('required')
      } else {
        stateInput.value = ''
        // stateSpanRequired.style.display = "none"
        stateInput.classList.remove('required')
      }
      if (statesRequired) {
        statePara.style.display = "block"
      } else {
        statePara.style.display = "none"
        stateInput.disabled = true
      }

      stateInput.classList.remove('hidden')
      stateSelect.classList.remove('required')
    }
  }

  updateBillingFormState(orderUseShipping) {
    var self = this;
    if (orderUseShipping.checked) {
      document.getElementById('billing_address_wrapper').classList.add('hidden');
      document.querySelector('#billing_address_wrapper input').disabled = true
      document.querySelector('#billing_address_wrapper select').disabled = true
    } else {
      document.getElementById('billing_address_wrapper').classList.remove('hidden');
      document.querySelector('#billing_address_wrapper input').disabled = false
      document.querySelector('#billing_address_wrapper select').disabled = false
      self.updateState('b')
    }
  }

  getCountryId(region) {
    return document.querySelector('#' + region + 'country select').value
  }

  handle_state_change(e) {
    var target = e.target;
    var self = this;
    if (target.matches('#scountry select')) {
      self.updateState('s')
    }
    if (target.matches('#bcountry select')) {
      self.updateState('b')
    }
  }
}