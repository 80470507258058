import {
  Controller
} from "stimulus"
import {
  Houdini
} from "./mixins/houdini";
export default class extends Controller {
  connect() {
    Houdini(this)
    this.loadHoudini()
  }
}