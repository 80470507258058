import {
  Controller
} from "stimulus"
import Uri from "jsuri"
export default class extends Controller {
  connect() {
    Spree = {}
    Spree.url = function (uri, query) {
      if (uri.path === void 0) {
        // eslint-disable-next-line no-undef
        uri = new Uri(uri)
      }
      if (query) {
        query.forEach(function (key, value) {
          return uri.addQueryParam(key, value)
        })
      }
      return uri
    }
    Spree.mountedAt = function () {
      return window.SpreePaths.mounted_at
    }
    Spree.pathFor = function (path) {
      var locationOrigin = (window.location.protocol + '//' + window.location.hostname) + (window.location.port ? ':' + window.location.port : '')
      return this.url('' + locationOrigin + (this.mountedAt()) + path, this.url_params).toString()
    }
    Spree.routes = {
      states_search: Spree.pathFor('api/v1/states'),
      apply_coupon_code: function (orderId) {
        return Spree.pathFor('api/v1/orders/' + orderId + '/apply_coupon_code')
      },
      cart: Spree.pathFor('cart')
    }

    window.Spree = Spree;
  }
}