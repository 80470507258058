import {
  Controller
} from "stimulus"
export default class extends Controller {
  static targets = ["mainImageGallery","defaultImageBackground", "defaultImage", 'defaultImageLink', 'galleryImage', 'galleryImageLink']
  static values = {
    scrollTop: Boolean
  }
  connect() {
    this.initialize(document.querySelector('#add-to-cart-form'))
    this.addEventListenerStamped(document, 'stamped:reviews:loaded', this.stampedOverride);
    this.addEventListenerStamped(document, 'stamped:badges:loaded', this.stampedStarOverride);
    if (this.scrollTopValue) {
      setTimeout(function () {
        window.scrollTo({ top: 0 });
      }, 100);
    }
  }


  updateDefaultImage(e){
    var index = e.target.getAttribute('data-index')
    if (!e.detail.open_gallery) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.mainImageGalleryTarget.swiper.slideTo(index)
  }

  imageZoom(e){
    var zoomer = e.currentTarget;
    var offsetX = e.offsetX ? e.offsetX : e.touches[0].pageX
    var offsetY = e.offsetY ? e.offsetY : e.touches[0].pageX
    var x = offsetX / zoomer.offsetWidth * 100
    var y = offsetY / zoomer.offsetHeight * 100
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
  }

  showGallery(e){
    e.preventDefault();
    if(e.target.getAttribute('data-open-gallery') === 'true'){
      var index = e.target.getAttribute('data-index')
      document.querySelectorAll('.image-gallery a')[index].dispatchEvent(new CustomEvent('click', {
        bubbles: true,
        detail: {open_gallery: true}
      }));
    }
  }

  addEventListenerStamped(el, eventName, handler) {
    if (el.addEventListener) {
      el.addEventListener(eventName, handler);
    } else {
      el.attachEvent('on' + eventName, function () {
        handler.call(el);
      });
    }
  }
  stampedStarOverride(e) {
    let stamped_stars = document.querySelectorAll('.stamped-badge-caption')
    if (stamped_stars) {
      stamped_stars.forEach(function (el) {
        el.classList.remove('hidden')
      })
    }
    let stamped_zero_stars = document.querySelectorAll('.stamped-badge-caption[data-rating="0.0"]')
    
    if (stamped_zero_stars) {
      stamped_zero_stars.forEach(function (el) {
        el.parentElement.remove()
      })
    }
  }
  stampedOverride(e){
    let stamped_container = document.querySelector('.stamped-container')
    
    if (stamped_container) {
      stamped_container.classList.add('font-body')
      stamped_container.querySelector('.stamped-header-title').classList.add('lead-h2')
      stamped_container.querySelector('.stamped-summary-actions').classList.add('flex')
      stamped_container.querySelector('.stamped-summary-actions').classList.add('justify-end')
      stamped_container.querySelector('.stamped-summary-text-1 strong').classList.add('lead-h2')
      stamped_container.querySelector('.stamped-tab-container').remove()
      stamped_container.querySelector('.stamped-reviews-filter').remove()
      var new_review = stamped_container.querySelector('.stamped-summary-actions-newreview')

      new_review.classList = null
      new_review.classList.add('btn', 'btn-primary')
      stamped_container.querySelector('.stamped-empty-state div').classList.add('lead-h4')
      stamped_container.querySelectorAll('.stamped-form-label').forEach((el) => {
        el.classList.add('label', 'mb-2', 'w-full');
      });
      stamped_container.querySelectorAll('.stamped-review-header-title').forEach((el) => {
        el.classList = null;
        el.classList.add('italic', 'font-medium', 'text-12', 'tracking-neg-25', 'leading-18', 'md:text-15', 'md:leading-25');
      });
      stamped_container.querySelectorAll('input.stamped-form-input').forEach((el) => {
        el.classList = null;
        el.classList.add('form-control', 'w-full', 'mb-3');
      });
      stamped_container.querySelector('.stamped-form-input-textarea').classList.add('form-control', 'w-full', 'mb-3');
      stamped_container.querySelector('#stamped-button-submit').classList = null
    }
  }

  disconnect() {

    document.removeEventListener('stamped:reviews:loaded', this.stampedOverride)
  }
}