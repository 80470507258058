import {
  Controller
} from "stimulus"
export default class extends Controller {
  connect() {
    if(document.getElementById('storeSelectModal')){
      document.getElementById('storeSelectModal').modal.refresh()
    }
  }

  handle_images(event) {
    var image = event.target.getAttribute('data-image')
    var image_target = document.getElementById(event.target.getAttribute('data-image-target'));
    if (image_target && image) {
      image_target.src = image
      return;
    }
  }

  disconnect() {

  }
}