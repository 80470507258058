export const lazyLoad = controller => {
  Object.assign(controller, {
    loadLazy(e) {
      import('lazyload').then((lazyload) => {
        if (typeof App.vendor.lazy.img === 'function') {
          App.vendor.lazy.img.destroy();
        }
        App.vendor.lazy.img = new lazyload.default(document.querySelectorAll("img"));
        return true;
      });
    },
    destroyLazy(e){
      if (typeof App.vendor.lazy.img === 'function') {
        App.vendor.lazy.img.destroy();
      }
    }
  });
};