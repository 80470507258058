import {
  Controller
} from "stimulus"
export default class extends Controller {
  connect() {
  }

  disconnect(e) {

  }
  scrollToProducts(e) {
    e.preventDefault();
    document.getElementById('orderAssistantProducts').scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "start"
    })
  }
}
