import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ['submit']
  connect() {
  }


  updateStockLocationId(e){
    // document.querySelector(`#${e.target.getAttribute('data-dom-id')} .stockLocationId`).value = e.target.getAttribute('data-stock-location-id')
    e.target.closest('form').classList.add('opacity-75')
    e.target.closest('form').requestSubmit();
    e.target.closest('form').querySelectorAll('a').forEach(element => {
      element.remove()
    });
    e.target.closest('form').querySelectorAll('h4').forEach(element => {
      element.disabled = true
      element.classList.add('cursor-not-allowed')
    });
    e.target.closest('form').elements.forEach(element => {
      element.disabled = true
      element.classList.add('cursor-not-allowed')
    });
    e.target.closest('form').querySelectorAll('label').forEach(element => {
      element.disabled = true
      element.classList.remove('cursor-pointer')
      element.classList.add('cursor-not-allowed')
    });
    e.target.closest('form').classList.add('cursor-not-allowed')
    e.target.closest('form').disabled = true
    App.vendor.rails.disableElement(this.submitTarget);
  }


  disconnect() {}
}