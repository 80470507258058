import {
    Controller
} from "stimulus"
export default class extends Controller {
    static targets = ["content"]

    connect() {
        this.contentTarget.querySelectorAll('a').forEach(function(el){
            el.setAttribute('target', '_BLANK')
        })
    }

    disconnect() {}
}