import {
  Controller
} from "stimulus"

export default class extends Controller {
  static values = {
    lineItems: Number,
  }
  connect() {
    this.pageTitle = document.title;
    this.blinkEvent = null;
    this.attentionMessages = ['Are you ready to rock?', 'Let\'s get this project started!'];
    this.titleInterval = 0;
    document.addEventListener('visibilitychange', function (e) {
      var isPageActive = !document.hidden;

      if (!isPageActive && this.lineItemsValue > 0) {
        this.blinkTitle();
      } else {
        document.title = this.pageTitle;
        clearInterval(this.blinkEvent);
      }
    }.bind(this));
  }

  blinkTitle() {
    this.blinkEvent = setInterval(function () {
      document.title = `(${this.lineItemsValue}) ${this.attentionMessages[this.titleInterval]}`;
      this.titleInterval = ++this.titleInterval % this.attentionMessages.length;
    }.bind(this), 2500);
  }
  disconnect() {
    clearInterval(this.blinkEvent);
  }

  toggleMobile() {
    this.mobileTarget.classList.toggle('hidden')
    if (this.mobileTarget.classList.contains('hidden')) {
      this.mobileTextTarget.innerHTML = 'Menu'
    } else {
      this.mobileTextTarget.innerHTML = 'Close'
    }
  }

}