import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["loader"]
  static values = {
    step: Number,
    lineItemCount: Number,
  }


  connect() {
    if (this.lineItemCountValue <= 0) {
      this.resetForm()
      document.body.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "start"
      })
    }else{
      this.element.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "start"
      })
    }
  }

  disconnect(e) {


  }
  backToTop(e) {
    if (document.getElementById('orderAssistantProducts')) {
      document.getElementById('orderAssistantProducts').innerHTML = ''
    }
    if (document.getElementById('orderAssistantProduct')) {
      document.getElementById('orderAssistantProduct').innerHTML = ''
    }
    const forms = document.querySelectorAll('form');

    for (const form of forms) {
      form.reset();
    }
    document.body.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start"
    })

  }
  resetForm() {
    if (document.getElementById('orderAssistantProducts')) {
      document.getElementById('orderAssistantProducts').innerHTML = ''
    }
    if (document.getElementById('orderAssistantProduct')) {
      document.getElementById('orderAssistantProduct').innerHTML = ''
    }
    if (document.getElementById('orderAssistantForm')) {
      document.getElementById('orderAssistantForm').innerHTML = ''
    }
    const forms = document.querySelectorAll('form');

    // Loop through each form and reset it
    for (const form of forms) {
      form.reset();
    }
  }


}
