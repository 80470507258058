export const HyperForm = controller => {
  Object.assign(controller, {
    loadHyperForm(element) {
      import('hyperform').then((hyperForm) => {
        var scope = element ? document.querySelector(element) : window
        if(scope){
          App.vendor.hyperForm = new hyperForm.default(scope);
        }
        if (document.getElementById('spree_user_password_confirmation') && document.getElementById('spree_user_password')){
          hyperForm.default.addValidator(
            document.getElementById('spree_user_password_confirmation'),
            function (element) {
              var valid = element.value === document.getElementById('spree_user_password').value;
              element.setCustomValidity(
                valid ?
                '' :
                'Please ensure both passwords match.'
              );
              return valid;
            }
          );
        }
        hyperForm.default.setRenderer('attachWarning', function (warning, element) {
          if(element.parentNode.classList.contains('input-addon-group')){
            element.parentNode.after(warning);
          }else{
            element.parentNode.appendChild(warning);
          }
        });
      })
    },
    destroyHyperForm(e) {
    }
  });
};