import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["zipCodeInput", "container"]
  static values = {
    url: String,
    scope: String
  }



  connect() {

  }

  disconnect() {

  }

  edit(e) {
    e.preventDefault()
      if (this.hasZipCodeInputTarget) {
        this.containerTarget.children[0].style.opacity = 0.5
        this.containerTarget.classList.add('spinner-md')
        this.containerTarget.classList.add('spinner-primary')
        var params = {
          zipcode: this.zipCodeInputTarget.value
        };
        this.zipCodeInputTarget.value = ''
          App.vendor.rails.ajax({
            type: "POST",
            url: this.urlValue,
            beforeSend: function(xhr, options){
              xhr.setRequestHeader('Accept', 'application/vnd.turbo.stream.html')
              xhr.setRequestHeader('Content-Type', 'application/json')
              options.data = JSON.stringify(params)
              return true
            }.bind(this),
            success: function (response) {
              // used to update opened quote modal window with newly added zipcode information
              if (this.hasScopeValue && this.scopeValue === 'quote') {
                document.getElementById('quoteSubmit').click()
              }
            }.bind(this)
          });

      }


  }

}



