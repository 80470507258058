import {
  Controller
} from "stimulus"
import {
  FormHelper
} from "../mixins/form_helper";
import Swal from "sweetalert2/dist/sweetalert2.js"
export default class extends Controller {
  static values = {
    remote: Boolean,
    error: Object
  }
  static targets = ["submit", "error"]


  connect() {
    FormHelper(this)
    // TODO:: This is not firing for some reason errorTarget is defined so is ErrorValue
    if (this.errorValue["quantity"]) {
        Swal.fire({
          target: this.errorTarget,
          html: this.errorValue["quantity"][0],
          toast: false, 
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
          customClass: {
            container: 'border border-primary shadow-md rounded-md p-3 mb-4 text-primary text-15 font-normal',
          }
        })
    } else {
      Swal.close()
    }
    document.addEventListener('turbo:submit-end', function () {
      this.element.querySelectorAll('form').forEach(el => {
        el.style.opacity = 1
        el.classList.remove('cursor-not-allowed')
        el.classList.remove('pointer-events-none')
        App.vendor.rails.enableElement(el)

      })
    }.bind(this))
  }

  disconnect() {}

  handleRemoteSubmit(event) {
    var self = this;
    if (!this.remoteValue) {
      return;
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      if (self.hasSubmitTarget){
        App.vendor.rails.disableElement(self.submitTarget)
      }
      event.target.closest('form').dispatchEvent(new Event('submit', {
        bubbles: true
      }));
      self.element.querySelectorAll('form').forEach(el => {
        el.style.opacity = 0.6
        el.classList.add('cursor-not-allowed')
        el.classList.add('pointer-events-none')
        App.vendor.rails.disableElement(el)
      })
      event.target.closest('form').style.opacity = 0.6
    }, 600);
  }
}