import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    favorite: Boolean
  }
  addToFavorites(e) {
    const coords = e.currentTarget
      .getBoundingClientRect();
    this.renderConfetti(coords);
  }
  renderConfetti(coords) {
    setTimeout(function () {
      if(!this.favoriteValue){
        import("@tsparticles/confetti").then((tsparticles) => {

          tsparticles.confetti("tsparticles", {
            fullScreen: { enable: true, zIndex: 0 },
            angle: 90,
            spread: 180,
            count: this.randomInRange(15, 32),
            shapes: ["hearts"],
            scalar: 2,
            colors: ["#ff4500", "red"],
            position: {
              y: (coords.y / window.innerHeight) * 100,
              x: (coords.x / window.innerWidth) * 100,
            },
          });
        });
      }
    }.bind(this),300)
  }
  connect() {
  }
  randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }


  disconnect() {
  }
}
