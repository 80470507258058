import {
  Controller
} from "stimulus"
export default class extends Controller {
  static targets = ["loader", "form"]
  static values = {
    step: Number,
    lineItemCount: Number,
    coverageCalculator: Boolean
  }


  connect() {
    if (!this.coverageCalculatorValue) {
      setTimeout(function () {
        this.element.style.scrollMarginTop = '60px'
        this.element.scrollIntoView({
          behavior: "smooth",
          inline: "nearest",
          block: "start"
        })
      }.bind(this), 10)
    }
    if(typeof App !== undefined && App.utils && App.utils.menu && App.utils.menu.headroom) {
      App.utils.menu.headroom.unpin()
      
    }
    this.element.closest('form').addEventListener("turbo:submit-end", (event) => {
      if(this.hasLoaderTarget){
        this.loaderTarget.classList.add('hidden')
      }

      event.target.classList.remove('opacity-75')
    }, this)
  }

  disconnect(e) {


  }
  scrollToProducts(e) {
    e.preventDefault();
    this.element.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "start"
    })
  }
  handleSubmit(e) {
    this.loaderTarget.classList.remove('hidden')
    this.loaderTarget.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "center"
    })
    App.utils.menu.headroom.unpin()
    if (document.getElementById('orderAssistantProduct')) {
      document.getElementById('orderAssistantProduct').innerHTML = ''
    }

    e.target.closest('form').classList.add('opacity-75')
    Turbo.navigator.submitForm(e.target.closest('form'))
  }

  handlePagination(e) {
    setTimeout(function () {
      App.utils.menu.headroom.unpin()
    }, 200)
    if (document.getElementById('orderAssistantProduct')) {
      document.getElementById('orderAssistantProduct').innerHTML = ''
    }
    this.element.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "start"
    })
    e.target.closest('form').classList.add('opacity-75')
  }
}
