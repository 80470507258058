import { Controller } from "stimulus";
import { FormHelper } from "../mixins/form_helper";
let debounce = require("lodash/debounce");
export default class extends Controller {
  static targets = [
    "quoteDisclaimer",
    "coverageCalculatorContainer",
    "coverageCalculatorResultsContainer",
    "coverageCalculatorResults",
    "coverageCalculatorSubmit",
    "messages",
    "stockLocations",
    "stockLocationsContainer",
    "specialOrder",
    "variantPer",
    "coverageCalculator",
    "variantPerUom",
    "quoteSubmit",
    "cartSubmit",
    "form",
    "variantId",
    "variantPer",
    "variantPerId",
    "price",
    "counterControl",
    "addToFavorites",
    "optionValue",
    "productVariants",
    "variantRates",
  ];
  static values = {
    variants: Array,
    quoteMode: Boolean,
    orderAssistant: Boolean,
    orderItemCount: Number,
    formMethod: String,
    product: String,
    size: String,
    color: String,
    material: String,
  };
  connect() {
    FormHelper(this);
    this.selectedOptionValueIds = [];
    this.withOptionValues = Boolean(this.optionValueTargets.length);
    this.calculatorMode = "dimensions";
    this.counterControlTarget.addEventListener(
      "change",
      this.updateCartMode.bind(this),
      true
    );
    this.initializeForm();
    this.mode = this.quoteModeValue ? "quote" : "order";
    this.defaultMode = this.mode;
    this.throttledGetRates = debounce(this.getRates, 600, true).bind(this);
    this.sidebarModal = document.getElementById("sidebar-navigation");
  }

  initializeForm() {
    if (this.withOptionValues) {
      if (this.hasSizeValue || this.hasColorValue || this.hasMaterialValue){
        this.handleUrlParams();
      }else{
        var optionValue = this.firstCheckedOptionValue();
        this.updateCheckedOptionValue(optionValue);
      }
      this.optionTypeSetup();
    } else {
      // var PerValue = this.selectedPer()
      // this.applyPerValue(PerValue)
      this.getVariantAttributes();
      this.updateCoverageCalculator();
    }
  }

  handleOptionTypeChange(e) {
    var option = e.target.options[e.target.selectedIndex];
    this.updateCheckedOptionValue(option);
    this.optionTypeSetup();
  };

  perSetup() {
    this.updateVariantPers();
    this.formSetup();
  }

  optionTypeSetup() {
    this.showAvailableVariants();
    this.updateAddToFavorites();
    this.updateVariantId();
    this.getVariantAttributes();
  }

  formSetup() {
    this.updateCoverageCalculator();
    this.updateCartMode();
    this.updatePrice();
    this.updateAddToCart();
  }

  showAvailableVariants() {
    const availableIds = this.availableOptionValueIds();
    const selectedCount = this.selectedOptionValueIds.length;

    this.productVariantsTargets.forEach((variant, index) => {
      if (index < selectedCount) return;

      variant.querySelectorAll(`.${this.optionValueTarget.classList[0]}`)
        .forEach(optionValue => {
          const id = parseInt(optionValue.value);
          optionValue.selected = false;
          optionValue.checked = false;
          optionValue.disabled = !availableIds.includes(id);
        });
    });
  }
    
  handleUrlParams() {
    const availableIds = this.availableOptionValueIds();
    const filters = [
      String(this.sizeValue.toLowerCase()),
      String(this.colorValue.toLowerCase()),
      String(this.materialValue.toLowerCase()),
    ].filter((filter) => filter);

    if (!filters.length) return;
    this.productVariantsTargets.forEach((optionType, index) => {
      const optionValues = optionType.querySelectorAll(
        `.${this.optionValueTarget.classList[0]}`
      );

      optionValues.forEach((ov) => {
        const id = parseInt(ov.value);
        const matchesFilter = filters.includes(ov.text.toLowerCase());
        console.log(ov.text.toLowerCase());
        ov.checked = matchesFilter;
        ov.selected = matchesFilter;
        if (matchesFilter) {
          this.updateCheckedOptionValue(ov);
        }
        ov.disabled = !availableIds.includes(id);
        
        this.optionTypeSetup();

      });
    });
  }
  availableOptionValueIds() {
    var selectedOptionValueIds = this.selectedOptionValueIds;
    return this.variantsValue.reduce(function (acc, variant) {
      var optionValues = variant.option_values.map(function (ov) {
        return ov.id;
      });

      var isPossibleVariantFound = selectedOptionValueIds.every(function (ov) {
        return optionValues.includes(ov);
      });

      if (isPossibleVariantFound) {
        return acc.concat(optionValues);
      }

      return acc;
    }, []);
  }

  firstCheckedOptionValue() {
    var firstAvailableOption = this.formTarget.querySelector(
      `.${this.optionValueTarget.classList[0]}[data-is-stocked="true"][data-is-purchasable="true"][data-position]`
    );
    if (firstAvailableOption == null) {
      firstAvailableOption = this.formTarget.querySelector(
        `.${this.optionValueTarget.classList[0]}[data-is-special-order="false"][data-position]`
      );
    }
    if (firstAvailableOption == null) {
      firstAvailableOption = this.formTarget.querySelector(
        `.${this.optionValueTarget.classList[0]}[data-position]`
      );
    }
    firstAvailableOption.selected = true;
    return firstAvailableOption;
  }

  selectedVariant() {
    var self = this;
    if (!this.withOptionValues) {
      return this.variantsValue.find(function (variant) {
        return variant.id === parseInt(self.variantIdTarget.value);
      });
    }

    if (this.variantsValue.length === 1 && this.variantsValue[0].is_master) {
      return this.variantsValue[0];
    }

    return this.variantsValue.find(function (variant) {
      var optionValueIds = variant.option_values.map(function (ov) {
        return ov.id;
      });
      return self.areArraysEqual(optionValueIds, self.selectedOptionValueIds);
    });
  }

  selectedPer() {
    return this.formTarget.querySelector(
      `input[name="order[line_items_attributes][variant_per_id]"]:checked`
    );
  }

  areArraysEqual(array1, array2) {
    return (
      this.sortArray(array1).join(",") === this.sortArray(array2).join(",")
    );
  }

  sortArray(array) {
    return array.concat().sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;

      return 0;
    });
  }

  canSupply() {
    if (!this.selectedVariant() || !this.selectedPer()) return false;
    var stock_items = this.selectedVariant().stock_items;
    var supplyable;
    supplyable = stock_items.some(
      function (stock_location) {
        return stock_location.count_on_hand >= this.requiredQuantity();
      }.bind(this)
    );
    return supplyable;
  }

  requiredQuantity() {
    var conversion_to_base =
      parseFloat(this.selectedPer().getAttribute("data-conversion-to-base")) ||
      1;
    return this.counterControlTarget.value * conversion_to_base;
  }

  handleCoverageDimensions(e) {
    if (!this.hasCoverageCalculatorTarget) {
      return;
    }
    if (!this.hasCoverageCalculatorContainerTarget) {
      return;
    }
    this.calculatorMode = "dimensions";

    var input_length =
      parseInt(this.formTarget.querySelector("#dimensions_length").value) || 1;
    var input_width =
      parseInt(this.formTarget.querySelector("#dimensions_width").value) || 1;
    var input_depth =
      parseInt(this.formTarget.querySelector("#dimensions_depth").value) || 1;
    var requested_vol = input_width * input_length * input_depth;
    var coverage_in_vol = this.selectedPerCoverage() * 2;

    var new_quantity = requested_vol / coverage_in_vol;
    if (!isFinite(new_quantity)) {
      new_quantity = 1;
    }
    return Math.round(new_quantity * 4) / 4;
  }

  handleCoverageSquareFootage(e) {
    if (!this.hasCoverageCalculatorTarget) {
      return;
    }
    if (!this.hasCoverageCalculatorContainerTarget) {
      return;
    }
    this.calculatorMode = "square_footage";
    var input_width =
      parseInt(this.formTarget.querySelector("#square_footage_feet").value) ||
      1;
    var input_depth =
      parseInt(this.formTarget.querySelector("#square_footage_depth").value) ||
      1;
    var requested_vol = input_width * input_depth;

    var coverage_in_vol = this.selectedPerCoverage() * 2;
    var new_quantity = requested_vol / coverage_in_vol;
    if (!isFinite(new_quantity)) {
      new_quantity = 1;
    }
    return Math.round(new_quantity * 4) / 4;
  }

  handleCoverageCalculator(e) {
    if (!this.hasCoverageCalculatorTarget) {
      return;
    }
    if (!this.hasCoverageCalculatorContainerTarget) {
      return;
    }
    if (this.calculatorMode == "square_footage") {
      var calculated_result = this.handleCoverageSquareFootage(e);
    } else {
      var calculated_result = this.handleCoverageDimensions(e);
    }
    this.formTarget.reportValidity();
    e.preventDefault();
    if (this.formTarget.checkValidity()) {
      this.coverageCalculatorTarget.classList.add("hidden");
      this.coverageCalculatorResultsContainerTarget.classList.remove("hidden");
      // check if string contains CY
      let pers_without_bulk = this.variantPers().filter(
        (per) => per.bulk === "false"
      );

      let is_cubic_yards =
        pers_without_bulk.length > 0 &&
        pers_without_bulk.every(
          (per) =>
            per.presentation.includes("Cubic Yard") ||
            per.presentation.includes("CF")
        );

      if (is_cubic_yards) {
        this.coverageCalculatorResultsTarget.innerHTML = `<h4 class="lead-h4 text-green text-18">You need approximately ${(
          calculated_result / 1.4
        ).toPrecision(3)} cubic yard${calculated_result === 1 ? "" : "s"} of ${
          this.productValue
        }</h4>`;
      } else {
        this.coverageCalculatorResultsTarget.innerHTML = `<h4 class="lead-h4 text-green text-18">You need approximately ${calculated_result} ton${
          calculated_result === 1 ? "" : "s"
        } (${(calculated_result * 2000).toLocaleString("en-US")} lbs.) of ${
          this.productValue
        }</h4>`;
      }
    }
  }

  handleCoverageCalculatorRecalculate(e) {
    e.preventDefault();
    this.coverageCalculatorTarget.classList.remove("hidden");
    this.coverageCalculatorResultsContainerTarget.classList.add("hidden");
  }

  selectedPerCoverage() {
    return parseFloat(
      this.formTarget
        .querySelector(
          `input[name="order[line_items_attributes][variant_per_id]"]:checked`
        )
        .getAttribute("data-common-coverage")
    );
  }

  updateCheckedOptionValue(optionValue) {
    var optionTypeIndex = optionValue.parentElement.getAttribute(
      "data-option-type-index"
    );
    if (optionTypeIndex) {
      this.selectedOptionValueIds.splice(
        optionTypeIndex,
        this.selectedOptionValueIds.length,
        parseInt(optionValue.value)
      );
    }
  }


  updatePrice() {
    var variant = this.selectedVariant();

    var variantPer = this.selectedPer();
    if (!variantPer || !variant) return;

    let price = variant.promotions.length
      ? variantPer.getAttribute("data-promotion-price")
      : variantPer.getAttribute("data-price");
    this.priceTarget.setAttribute("data-base-price", price);
    this.updatePerQuantityPrice();
  }

  updatePerQuantityPrice() {
    let price = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    }).format(
      this.counterControlTarget.value *
        parseFloat(this.priceTarget.getAttribute("data-base-price"))
    );
    let retailPrice = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    }).format(
      this.counterControlTarget.value *
        parseFloat(this.selectedPer().getAttribute("data-price"))
    );
    if (this.selectedVariant().promotions.length) {
      this.priceTarget.innerHTML = `<span class="text-primary mr-1">Now ${price}</span> <span class="text-17 md:text-18 line-through">${retailPrice}</span>`;
    } else {
      this.priceTarget.innerHTML = price;
      this.priceTarget.classList.remove("text-primary");
    }
  }

  updateCoverageCalculator() {
    if (!this.hasCoverageCalculatorTarget) {
      return;
    }
    if (!this.hasCoverageCalculatorContainerTarget) {
      return;
    }
    if (!this.selectedVariant() || !(this.selectedPerCoverage() > 0)) {
      this.coverageCalculatorContainerTarget.classList.add("hidden");
    } else {
      this.coverageCalculatorContainerTarget.classList.remove("hidden");
    }
    if (this.calculatorMode === "dimensions") {
      this.handleCoverageDimensions();
    } else {
      this.handleCoverageSquareFootage();
    }
  }

  updateCounterControlValue() {
    var variantPer = this.selectedPer();
    if (!variantPer) {
      return;
    }
    this.counterControlTarget.setAttribute(
      "step",
      variantPer.getAttribute("data-step")
    );
    this.counterControlTarget.setAttribute(
      "min",
      variantPer.getAttribute("data-min")
    );
    this.counterControlTarget.setAttribute(
      "max",
      variantPer.getAttribute("data-max")
    );
  }

  updateCartMode() {
    var variant = this.selectedVariant();
    var variantPer = this.selectedPer();
    var available_stock_locations = this.availableStockLocations();
    if (available_stock_locations) {
      this.stockLocationsContainerTarget.classList.remove("hidden");
      this.stockLocationsTarget.innerHTML = available_stock_locations;
    } else {
      this.stockLocationsContainerTarget.classList.add("hidden");
      this.stockLocationsTarget.innerHTML = "";
    }

    if (!variant) {
      return;
    }
    this.updateCoverageCalculator();
    this.updateCounterControlValue();
    if (this.quoteModeValue && this.canSupply()) {
      this.variantRatesTarget.classList.add("opacity-75");
      this.mode = this.defaultMode;
      this.throttledGetRates();
    } else {
      if (
        variant.purchasable &&
        this.canSupply() &&
        variantPer.getAttribute("data-purchasable") == "true"
      ) {
        this.specialOrderTarget.classList.add("hidden");
        this.variantRatesTarget.classList.add("opacity-75");
        this.mode = "order";
        this.variantRatesTarget.innerHTML = "";
        this.throttledGetRates();
      } else {
        this.variantRatesTarget.classList.add("opacity-75");
        this.mode = "quote";
        this.variantRatesTarget.innerHTML = "";
        this.throttledGetRates();
      }
    }
  }
  variantPers() {
    let data = [];
    this.formTarget
      .querySelectorAll(
        `input[name="order[line_items_attributes][variant_per_id]"]`
      )
      .forEach((per) => {
        data.push(JSON.parse(JSON.stringify(per.dataset)));
      });
    return data;
  }

  handleFormSubmit(e) {
    var variant = this.selectedVariant();
    var variantPer = this.formTarget.querySelector(
      `input[name="order[line_items_attributes][variant_per_id]"]:checked`
    );
    e.preventDefault();
    e.stopPropagation();
    this.formTarget.noValidate = true;
    e.preventDefault();
      if (variant && variantPer && variantPer.value) {
        if (!this.orderAssistantValue) {
          this.sidebarModal.modal.show();
        }
        this.formTarget.noValidate = true;
        this.formTarget.requestSubmit();
        this.orderItemCountValue++;
      } else {
        this.formTarget.noValidate = false;
        this.messagesTarget.classList.remove("hidden");
        this.messagesTarget.innerHTML =
          "Please select the product size and unit of measurement ";
      }
  }

  handleInlineNotification(e) {
    if (this.mode == "quote") {
      this.quoteSubmitTarget.classList.remove("btn-primary");
      this.quoteSubmitTarget.classList.add("btn-green");
      this.quoteSubmitTarget.innerHTML = "Added to Quote";
    } else {
      this.cartSubmitTarget.classList.add("btn-green");
      this.cartSubmitTarget.classList.remove("btn-primary");
      this.cartSubmitTarget.innerHTML = "Added to Cart";
    }
  }

  updateAddToCart() {
    var variant = this.selectedVariant();
    var variantPer = this.selectedPer();
    this.messagesTarget.classList.add("hidden");
    if (variant && variantPer && variantPer.value && this.mode) {
      if (this.mode === "quote") {
        this.formTarget.querySelector("#uomPrice").classList.add("hidden");
        this.priceTarget.innerHTML = null;
        if (!this.orderAssistantValue) {
          this.formTarget.setAttribute("action", "/cart?scope=sidebar");
        } else {
          this.formTarget.setAttribute("action", "/cart");
        }
        this.formTarget.setAttribute("method", this.formMethodValue);
        this.cartSubmitTarget.classList.add("hidden");
        this.quoteSubmitTarget.classList.remove("hidden");
        this.quoteDisclaimerTarget.classList.remove("hidden");
        this.quoteSubmitTarget.classList.add("btn-primary");
        this.quoteSubmitTarget.classList.remove("btn-green");
        if (this.orderItemCountValue >= 1) {
          this.quoteSubmitTarget.innerHTML =
            '<span class="mr-1 mt-1 ui-icon-quote"></span> Add to Quote';
        } else {
          this.quoteSubmitTarget.innerHTML =
            '<span class="mr-1 mt-1 ui-icon-quote"></span> Request a Quote';
        }
      } else {
        this.formTarget.querySelector("#uomPrice").classList.remove("hidden");
        this.formTarget.setAttribute("action", "/cart?scope=sidebar");
        this.formTarget.setAttribute("method", this.formMethodValue);
        this.quoteDisclaimerTarget.classList.add("hidden");
        this.quoteSubmitTarget.classList.add("hidden");
        this.cartSubmitTarget.classList.remove("hidden");
        this.cartSubmitTarget.classList.add("btn-primary");
        this.cartSubmitTarget.classList.remove("btn-green");
        this.cartSubmitTarget.classList.remove("hidden");
        this.cartSubmitTarget.innerHTML =
          '<span class="mr-1 mt-1 ui-icon-cart"></span> Add to Cart';
      }
    }
  }

  updateAddToFavorites() {
    if (this.selectedVariant()) {
      var variant = this.selectedVariant();
      this.addToFavoritesTarget.href = `/variants/${variant.id}/favorites`;
    }
    this.addToFavoritesTarget.disabled = this.selectedVariant() ? false : true;
  }

  updateVariantId() {
    var variant = this.selectedVariant();
    var variantId = (variant && variant.id) || "";
    this.variantIdTarget.value = variantId;
  }

  updateSpecialOrder() {
    var variantPer = this.selectedPer();

    if (
      variantPer.getAttribute("data-purchasable") == "true" ||
      this.availableStockLocations() ||
      variantPer.getAttribute.price
    ) {
      this.specialOrderTarget.classList.add("hidden");
    } else {
      this.specialOrderTarget.classList.remove("hidden");
    }
  }

  updateVariantPers() {
    var variant = this.selectedVariant();
    var variantPer = this.selectedPer();

    if (variant && variant.variant_pers > 1) {
      document.querySelector("#variantPerContainer").classList.remove("hidden");
    } else {
      document.querySelector("#variantPerContainer").classList.add("hidden");
    }
    if (!variant || !variantPer) return;
    this.counterControlTarget.value = 1;
    this.counterControlTarget.dispatchEvent(new Event("change"), {
      bubbles: true,
    });
    this.updateSpecialOrder();
    this.variantPerUomTarget.innerHTML = `Quantity (${variantPer.getAttribute(
      "data-presentation"
    )})`;
  }

  availableStockLocations() {
    if (!this.selectedVariant() || !this.selectedPer()) return null;
    var stock_items = this.selectedVariant().stock_items;
    if (
      !stock_items.some(function (sl) {
        return sl.count_on_hand > 0;
      })
    )
      return null;

    var supplyable = '<span class="text-17 md:text-18 flex flex-wrap w-full">';
    stock_items.forEach(
      function (stock_location) {
        if (stock_location.count_on_hand >= this.requiredQuantity()) {
          supplyable += `<a href="${stock_location.url}" target="_BLANK" title="${stock_location.name} Store Info" class="underline mr-3">${stock_location.name}</a>`;
        }
      }.bind(this)
    );
    supplyable += "</span>";
    return supplyable;
  }

  getVariantAttributes() {
    var variant = this.selectedVariant();
    var timeout;
    clearTimeout(timeout);
    if (!variant) return;

    // this.cartSubmitTarget.disabled = true
    // this.quoteSubmitTarget.disabled = true
    this.variantPerTarget.innerHTML = null;
    App.vendor.rails.ajax({
      type: "GET",
      url: `/variants/${variant.id}/attributes`,
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader("Accept", "application/vnd.turbo.stream.html");
        xhr.setRequestHeader(
          "Content-Type",
          "application/vnd.turbo.stream.html"
        );
        return true;
      },
      success: async function (response) {
        await Turbo.renderStreamMessage(await response)
        setTimeout(function () {
          if (this.hasVariantPerTarget) {
            this.perSetup();
          }
          this.formTarget.noValidate = false;
        }.bind(this),100)
      }.bind(this)
    })
  }

  getRates() {
    var variant = this.selectedVariant();
    var per = this.selectedPer();
    var quantity = this.counterControlTarget.value;
    var self = this;
    if (!variant || !per || variant.special_order || variant.custom_fabrication)
      return;
    var request = {
      rate: {
        variant_params: {
          variant_id: variant.id,
          variant_per_id: per.value,
          quantity: quantity,
        },
      },
      mode: this.mode,
    };
    App.vendor.rails.ajax({
      type: "POST",
      url: `/variants/${variant.id}/rates`,
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader("Accept", "application/vnd.turbo.stream.html");
        xhr.setRequestHeader("Content-Type", "application/json");
        options.data = JSON.stringify(request);
        self.variantRatesTarget.classList.add("block");
        return true;
      },
      success: function (response) {
        if (per !== self.selectedPer()) {
          return;
        }
        self.variantRatesTarget.innerHTML = Turbo.renderStreamMessage(response);
        self.variantRatesTarget.classList.remove("block");
        self.variantRatesTarget.classList.toggle("opacity-75");
      },
      error: function (response) {
        self.variantRatesTarget.innerHTML = "";
      },
    });
  }
}
