import {
  Controller
} from "stimulus"
export default class extends Controller {
  static values = {
    product: Object
  }
  connect() {
  }
  disconnect() {

  }
  selectPromotion(e){
    if(typeof zaraz === 'undefined') {return};
    zaraz.ecommerce('Clicked Promotion', {
      products: [ this.productValue ]
    });
  }
  selectItem(){
    if(typeof zaraz === 'undefined') {return};
    zaraz.ecommerce('Product Clicked', {
      products: [ this.productValue ]
    });
  }
}
