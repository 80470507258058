import {
  Controller
} from "stimulus"
export default class extends Controller {
  static targets = ["loader"]

  connect() {
    this.element.style.scrollMarginTop = "60px";
    this.element.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "start"
    })
    this.element.querySelector('form').addEventListener("turbo:submit-end", (event) => {
      if(this.hasLoaderTarget){
        this.loaderTarget.classList.add('hidden')
      }
      if(this.hasFormTarget){
        this.formTarget.classList.remove('opacity-75')
      }
    }, this)
  }

  disconnect(e) {

  }

  handleSubmit(e) {
    this.loaderTarget.classList.remove('hidden')
    this.loaderTarget.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "center"
    })
    App.utils.menu.headroom.unpin()
    e.target.closest('form').classList.add('opacity-75')
    Turbo.navigator.submitForm(e.target.closest('form'))
  }
}
