import {
  Controller
} from "stimulus"
import Swal from "sweetalert2/dist/sweetalert2.js"
export default class extends Controller {
static values = {
  updated: Boolean,
  error: Object
}
static targets = ["messages", "error"]

  connect() {
    this.element.style.opacity = 1;
    if (this.errorValue["base"]) {
      Swal.fire({
        target: this.errorTarget,
        html: this.errorValue["base"][0],
        toast: false,
        showConfirmButton: false,
        timer: 10000,
        timerProgressBar: true,
        customClass: {
          container: 'border border-primary shadow-md rounded-md p-3 mb-4 text-primary text-15 font-normal',
        }
      })
    }else{
      if (this.updatedValue) {
        Swal.fire({
          target: this.messagesTarget,
          html: 'Quantity has been updated!',
          toast: false,
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
          customClass: {
            container: 'inline-notification text-primary text-15 italic font-light',
          }
        })
      }
    }
  }

  disconnect() {}
}