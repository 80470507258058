import {
  Controller
} from "stimulus"
import {
  Sharer
} from "../mixins/sharer";
export default class extends Controller {
  connect() {
    Sharer(this)
    this.loadSharer()
    var elems = document.querySelectorAll('[data-sharer]'),
      i,
      l = elems.length;

    for (i = 0; i < l; i++) {
      elems[i].addEventListener('click', function (e) {
        dataLayer.push({
          event: "share",
          method: this.getAttribute('data-sharer'),
          item_id: this.getAttribute('data-url')
        });
      });
    }



  }
  disconnect() {

  }
}
