const Rails = require("./rails_ujs")
Rails.start();

import {
  Turbo
} from "@hotwired/turbo-rails"
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};
Turbo.StreamActions.reload = function () {
  window.location.reload();
};
Turbo.StreamActions.closeModal = function () {
  App.vendor.modals.find(modal => modal.element.id == this.target).close();
  App.vendor.modals.find(modal => modal.element.id == this.target).refresh();
};

window.App = {
  configuration: {
    chat: {
      departments: [{
        Id: 'General Sales'
      }, {
        Id: 'Commercial Sales'
      }]
    },
    routes: {
      simple_favorites: '/simple_favorites'
    },
    stamped: {
      apiKey: 'pubkey-sN5QkX4oAf4S46kzr7NhdrfbDA3I2q',
      storeUrl: 'www.southwestboulder.com'
    }
  },
  utils: {
    flashes: {},
    menu: {},
    spree: {},
    houdini: {}
  },
  vendor: {
    rails: Rails,
    turbo: Turbo,
    lazy: [],
    houdinis: [],
    modals: [],
    swiper: {
      elements: []
    },
    sidebar: document.getElementById('sidebar-navigation')
  },
  store: {
    flashes: [],
    xhr: {
      xhrPool: []
    },
    user: {
      user_id: document.cookie.match(new RegExp('(^| )user_id=([^;]+)')) ? document.cookie.match(new RegExp('(^| )user_id=([^;]+)'))[2] : '',
      preferred_retail_location: document.cookie.match(new RegExp('(^| )preferred_retail_location=([^;]+)')) ? document.cookie.match(new RegExp('(^| )preferred_retail_location=([^;]+)'))[2] : false
    }
  },
  events: {},
  accessibility: {}
}

import {
  Application
} from "stimulus"
import {
  definitionsFromContext
} from "stimulus/webpack-helpers"


const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
application.handleError = (error, message, detail) => {
  console.log(error)
}
window.onerror = function myErrorHandler(error, url, lineNumber) {
  application.handleError(error)
  return false;
}


export default App;
