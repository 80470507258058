import {
  Controller
} from "stimulus"
export default class extends Controller {
  connect() {
    const textContainer = document.querySelector('.lead-h1');
    const textChars = textContainer.textContent.split("");
    textContainer.textContent = ''
    const buildChars = []

    textChars.forEach(function (c, index) {
      textContainer.innerHTML += "<span>" + c + "</span>"
    })

    let char = 0;
    let timer = setInterval(onTick, 50);

    function onTick() {
      const span = textContainer.querySelectorAll('span')[char]
      span.classList.add('fade');
      char++;
      if (char === textChars.length) {
        complete();
        return;
      }
    }

    function complete() {
      clearInterval(timer)
      timer = null;
}
  }

  disconnect(e) {

  }

}
