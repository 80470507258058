import { Controller } from "stimulus";
export default class extends Controller {
  static values = {
    enabled: Boolean,
    pattern: Array
  }
  connect() {

  }
  vibrate(e) {
    if (this.enabledValue && window.navigator.vibrate) {
      window.navigator.vibrate(this.patternValue);
    }
  }
}
