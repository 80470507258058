import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["textArea", "content"]
  static values = {
    order: Object,
  }
  connect() {
    this.textAreaTarget.value = JSON.stringify(this.orderValue, undefined, 2);
    this.textAreaTarget.disabled = true
  }
  show(){
    this.contentTarget.classList.remove('hidden')
  }
  close(){
    this.contentTarget.classList.add('hidden')
  }
  disconnect(){
    this.contentTarget.classList.add('hidden')
  }
}