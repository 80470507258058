import {
    Controller
} from "stimulus"
import A11yDialog from 'a11y-dialog'
import {
    Sharer
} from "./mixins/sharer";
export default class extends Controller {
    static targets = ["modal", "container", "shareContainer"]
    static values = {
        orientation: String,
    }
    connect() {
        this.hotspots = []
        this.modalTargets.forEach(function(el){
            let hotspot = new A11yDialog(el)
            this.hotspots.push(hotspot);
            hotspot.on('hide', function(){
                this.closeHotspotModal()
            }.bind(this))
        }.bind(this))
        Sharer(this)
        this.loadSharer()
    }

    disconnect() {
        this.hotspots.forEach(function(hotspot){
            hotspot.off('hide')
        })
        this.sharerDialog.off('show')
    }


    openShareModal(e){
        this.shareContainerTarget.classList.remove('hidden')
        this.closeHotspotModal()
    }
    closeShareModal(e){
        this.shareContainerTarget.classList.add('hidden')
    }

    openHotspotModal(e){
      if (screen.width >= 400){
          this.closeHotspotModal()
          this.containerTarget.querySelectorAll(`div[data-hotspot-reference]`).forEach(function (el) {
              el.classList.add('hotspot-pulse')
          })
          e.currentTarget.classList.remove('hotspot-pulse')
          this.drawLineBetweenDivs(e.target)
      }else{
          let product = document.querySelector(`#product_${e.target.getAttribute('data-hotspot-id')}`)
          product.scrollIntoView({
              block: 'center',
              behavior: 'smooth', 
              inline: "nearest"
          });
          product.classList.add('flash-border')
      }
    }

    closeHotspotModal() {
        this.hotspots.forEach(function(hotspot){
            hotspot.hide()
        })
        this.containerTarget.querySelectorAll('svg').forEach(function(el){
            el.remove()
        })
        this.containerTarget.querySelectorAll(`div[data-hotspot-reference]`).forEach(function (el) {
            el.classList.add('hotspot-pulse')
        })
    }

    drawLineBetweenDivs(div) {
        // Get the start and end div elements

        // Create a canvas element and append it to the document body
    // Get the coordinates of the center points of the div elements
    // Get the coordinates of the top-left corners of the div elements
    // Get the coordinates of the top-left corner of the div element
        const divCenter = {
            x: div.offsetLeft + div.offsetWidth / 2,
            y: div.offsetTop + div.offsetHeight / 2
        };

        // Get the coordinates of the center of the relative container
        const containerCenter = {
            x: this.containerTarget.offsetWidth / 2,
            y: this.containerTarget.offsetHeight / 2
        };

        // Create an SVG element and set its attributes
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.classList.add('hidden')
        svg.classList.add('md:absolute')
        svg.classList.add('md:block')
        svg.classList.add('top-0')
        svg.classList.add('right-0')
        if(this.orientationValue == 'portrait'){
            svg.classList.add('h-full')
            svg.classList.add('w-full')
            svg.classList.add('mx-auto')
        }else{
            svg.classList.add('h-full')
            svg.classList.add('w-full')
        }
        svg.style.top = '0';
        svg.style.left = '0';
        svg.style.pointerEvents = 'none';

        // Create a line element and set its attributes
        const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        line.setAttribute('x1', divCenter.x);
        line.setAttribute('y1', divCenter.y);
        line.setAttribute('x2', containerCenter.x);
        line.setAttribute('y2', containerCenter.y);
        line.style.stroke = '#ff4500'; // Customize the line color here
        line.style.strokeWidth = '2px'; // Customize the line width here

        // Append the line element to the SVG element
        svg.appendChild(line);
        this.containerTarget.appendChild(svg);


        // Remove the canvas element after drawing the line
        // document.body.removeChild(canvas);
    }

}