import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["value", "counterDecrement", "counterIncrement"]
  static values = {
    remote: Boolean,
    decimals: Boolean
  }

  connect() {
    this.counterInput = this.valueTarget;
    this.isRemote = this.counterInput.getAttribute('data-remote') === 'true'
    this.count = parseFloat(this.counterInput.value);
    this.allowDestroy = this.counterInput.getAttribute('data-allow-destroy') === 'true'
  }

  disconnect() {

  }
  handleCounterChange() {
    this.count = parseFloat(this.counterInput.value)
  }

  handleTickUp(e) {
    let step = parseFloat(this.counterInput.getAttribute('step'))
    let max = parseFloat(this.counterInput.getAttribute('max'))
    this.count = this.count + step
    if (this.count >= max) {
      this.count = max
    }

    if (this.count > 1 && this.hasCounterDecrementTarget) {
      this.counterDecrementTarget.querySelector('span').classList.remove('bt-trash')
      this.counterDecrementTarget.querySelector('span').classList.add('bt-minus')
    }
    this.counterInput.value = step === 1 ? this.count : (this.decimalsValue ? this.count.toFixed(2) : this.count.toFixed(2));
    this.counterInput.dispatchEvent(new Event('change'), {
      bubbles: true
    })

  }

  handleTickDown(e) {
    let step = parseFloat(this.counterInput.getAttribute('step'))
    let min = parseFloat(this.counterInput.getAttribute('min'))

    this.count = this.count - step

    if (this.count <= 0 || this.count <= min) {
      if (!this.allowDestroy) {
        this.count = parseFloat(this.counterInput.getAttribute('min'))
      } else if (this.count < min) {
        this.count = 0
      }
    }
    if (this.count === min && this.hasCounterIncrementTarget) {
      this.counterDecrementTarget.querySelector('span').classList.add('bt-trash')
      this.counterDecrementTarget.querySelector('span').classList.remove('bt-minus')
    }
    this.counterInput.value = step === 1 ? this.count : (this.decimalsValue ? this.count.toFixed(2) : this.count.toFixed(2));
    this.counterInput.dispatchEvent(new Event('change'), {
      bubbles: true
    })
  }
}