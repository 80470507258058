import {
  Controller
} from "stimulus"
export default class extends Controller {
  static targets = ["loader"]
  connect() {
    this.element.closest('form').addEventListener("turbo:submit-end", (event) => {
      this.loaderTarget.classList.add('hidden')
      event.target.classList.remove('opacity-75')
    }, this)
  }

  disconnect(e) {

  }

  handleSubmit(e) {
    this.loaderTarget.classList.remove('hidden')
    this.loaderTarget.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "center"
    })

    if (document.getElementById('orderAssistantProducts')) {
      document.getElementById('orderAssistantProducts').innerHTML = ''
    }
    if (document.getElementById('orderAssistantProduct')) {
      document.getElementById('orderAssistantProduct').innerHTML = ''
    }

    App.utils.menu.headroom.unpin()


    e.target.closest('form').classList.add('opacity-75')
    Turbo.navigator.submitForm(e.target.closest('form'))
  }
}
