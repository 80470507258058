import {
  Controller
} from "stimulus"
export default class extends Controller {
  static values = {
      chatToggle: Boolean,
  }
  connect() {
    document.addEventListener('chatwootLoaded', function () {
      if (this.chatToggleValue) {
        window.$chatwoot.toggle();
      }
    }.bind(this));
  }

  disconnect() {
  }
}