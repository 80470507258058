import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["results", "form", 'input']
  timeout
  initializeMicrophone(e){ 
    e.preventDefault()
    // The speech recognition interface lives on the browser’s window object
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition; // if none exists -> undefined

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      // recognition.lang = "en-US"
      recognition.start(); // First time you have to allow access to mic!
      this.inputTarget.focus();
      recognition.addEventListener("result", this.handleMicrophoneResults.bind(this)); // <=> recognition.onresult = function(event) {...} - Fires when you stop talking
    } else {
      console.log("Your Browser does not support speech Recognition");
    }
  }

  handleMicrophoneResults(event) {
    const current = event.resultIndex;
    const transcript = event.results[current][0].transcript;
    if (transcript.toLowerCase().trim() === "stop recording") {
      recognition.stop();
    } else if (!this.inputTarget.value) {
      this.inputTarget.value = transcript;
    } else {
      if (transcript.toLowerCase().trim() === "go" || transcript.toLowerCase().trim() === "engage") {
        this.formTarget.submit();
      } else if (transcript.toLowerCase().trim() === "reset input") {
        this.inputTarget.value = "";
      } else {
        this.inputTarget.value = transcript;
      }
    }
    // searchFormInput.value = transcript;
    // searchFormInput.focus();
    // setTimeout(() => {
    //   searchForm.submit();
    // }, 500);
  }

  connect() {
    this.formTarget.setAttribute('autocomplete', 'off')
    setTimeout(function(){
      this.formTarget.setAttribute('autocomplete', 'on')
    }.bind(this),100)
    this.words = ["What can we help you with today?", "Search for Boulders", "Search for Flagstone", "Search for River Rock", "Search for Crushed Rock", "Search for Gravel", "Search for Pebble", "Search for Decomposed Granite", "Search for Mulch", "Search for Fountains", "Search for Furniture", "Search for Stone Veneer", "Search for Promotions", "Search for Construction Rock", "Search for Sand", "Search for Sealers", "Search for Stone Care", "Search for Pavers", "Search for Stepping Stones", "Search for Building Stone", "Search for Ledgestone", "Search for Custom Stone Fabrication", "Search for Landscaping Supplies", "Search for Sealers", "Search for Cleaners", "Search for Stabilizers", "Search for Adhesives", "Search for Binders", "Search for Concrete", "Search for Cement", "Search for Masonry"];
    import('typed.js').then((Typed) => {
      this.typed = new Typed.default(`#${this.inputTarget.id}`, {
        strings: this.words,
        typeSpeed: 75,
        attr: 'placeholder',
        backDelay: 5000,
        backSpeed: 150,
        loop: true
      });
    })
  }
  
  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.resultsTarget.querySelector('#filter-content').style.opacity = 0.5
      this.formTarget.submit();
    }, 10000)
  }

  jumpToAnchor(e){
    let anchor =  document.getElementById(e.target.value)
    if (anchor){
      anchor.scrollIntoView();
    }
  }

  submit(e) {
    e.preventDefault();
    this.resultsTarget.querySelector('#filter-content').style.opacity = 0.5
    this.formTarget.submit();
  }

  disconnect(){
  }




  handleResults(event) {
    const [data, status, xhr] = event.detail
    App.vendor.turbo.clearCache()
    this.resultsTarget.querySelector('#filter-content').style.opacity = 1
    this.resultsTarget.innerHTML = event.detail[0].querySelector('[data-controller="search"]').innerHTML
    window.scrollTo({top:0,left:0,behavior: 'smooth'});
  }
}

