import {
  Controller
} from "stimulus"
import noUiSlider from 'nouislider';

export default class extends Controller {
  static targets = ['form','filter','leftFilter', 'rightFilter', 'tooltip']
  static values = {
    optionValues: Array,
    min: Number,
    max: Number,
    selectedMin: Number,
    selectedMax: Number
  }
  connect() {
    var timeout;
    var format = {
      to: function (value) {
        if (this.optionValueByPosition(Math.round(value))) {
          return this.optionValueByPosition(Math.round(value)).presentation;
        }
        // return ''
      }.bind(this),
      from: function (value) {
        if (this.optionValueByPresentation(value)) {
          return this.optionValueByPresentation(value).position;
        }
        // return ''
      }.bind(this)
    }
    noUiSlider.create(this.filterTarget, {
      start: [this.optionValueByPosition(this.selectedMinValue).presentation, this.optionValueByPosition(this.selectedMaxValue).presentation],
      range: {
        min: this.minValue,
        max: this.maxValue
      },
      connect: true,
      format: format,
      pips: {
        mode: 'steps',
        format: format,
      },
    });
    this.filterTarget.noUiSlider.on('set', function (e) {
      clearTimeout(timeout)
      timeout = setTimeout(function(){
        this.leftFilterTarget.closest('form').classList.add(['opacity-75', 'spinner', 'spinner-md', 'spinner-primary'])
        this.leftFilterTarget.closest('form').requestSubmit();
      }.bind(this), 1000)
    }.bind(this));

    this.filterTarget.noUiSlider.on('update', function (e) {
      if (document.getElementsByClassName('noUi-marker-large').length){
        document.getElementsByClassName('noUi-marker-large')[1].style.display = 'none';
      }
      var ov1 = this.optionValueByPresentation(e[0])
      var ov2 = this.optionValueByPresentation(e[1])

      if (ov1) {
        this.leftFilterTarget.value = ov1.position
      }
      if (ov2) {
        this.rightFilterTarget.value = ov2.position
      }
      if(ov1 && ov2){
        this.tooltipTarget.innerHTML = `${ov1.presentation} - ${ov2.presentation}`
      }
    }.bind(this));


  }

  positionMedian(values) {
    if (values.length === 0) throw new Error("No inputs");

    values.sort(function (a, b) {
      return a.position - b.position;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2)
      return values[half].position;

    return (values[half - 1].position + values[half].position) / 2.0;
  }

  optionValueByPresentation(value){
    return this.optionValuesValue.find(o => o.presentation == value)
  }
  optionValueByPosition(value){
    return this.optionValuesValue.find(o => o.position == value)
  }
  
  disconnect() {
    this.filterTarget.noUiSlider.off('set')
    this.filterTarget.noUiSlider.off('update')
    this.filterTarget.noUiSlider.destroy()
  }
}