export const storeLocator = controller => {
  Object.assign(controller, {
    loadStoreLocator(e) {
      if (typeof App.vendor.store_locator == 'undefined') {
        import('store-locator').then((StoreLocator) => {
          App.vendor.store_locator = new StoreLocator.default({
            lookup(request, done) {
              if (document.querySelector('.js-loader')){
                document.querySelector('.js-loader').classList.remove('hidden')
              }
              App.vendor.rails.ajax({
                type: "POST",
                url: `/preferences/stock_locations.json`,
                dataType: 'application/json',
                data: new URLSearchParams(request).toString(),
                headers: {
                  'X-CSRF-Token': App.vendor.rails.csrfToken(),
                  'X-Requested-With': 'XMLHttpRequest'
                },
                success: function (data) {
                  if (document.querySelector('.js-loader')) {
                    document.querySelector('.js-loader').classList.add('hidden')
                    App.vendor.rails.enableElement(document.querySelector('#storeFinder'))
                  }
                  done(data)
                }
              });
            },
            settings: {
              key: 'AIzaSyDWo_YmPjDcpNIWkgxwbIXAg3jVODR61DA',
              lang: 'en',
              region: 'US',
              // icon (location) {
              //     return '<span class="bts bt-heart></span>'
              // },              
              disableDefaultUI: true,
              paginate: false,
              zoom: 10,
              center: {
                lat: 32.715736,
                lng: -117.161087
              },
              setStoreEndpoint: 'preferences/stock_locations.js'
            },
            elements: {
              map: '.js-map',
              sidebar: '.js-sidebar',
              form: '.js-form',
              autocomplete: '.js-autocomplete',
              pagination: '.js-pagination',
              nextPage: '.js-next',
              prevPage: '.js-prev',
              filter: '.js-filter',
              redo: '.js-redo',
              geolocation: '.js-geolocation',
              geolocationFeedback: '.js-geolocation-feedback',
              returntosidebar: '.js-returntosidebar'
            },
            templates: {
              sidebar(location) {
                var storeText = location.details.preferred_location ? 'Unselect Store' : 'Set as Store'
                return `<div class="hover:shadow-md cursor-pointer flex justify-between p-2 md:p-3 space-x-3">` +
                `<div class="text-18 flex-grow">` +
                `<div class="">${location.address}</div>` +
                `<div class="">${location.city}, ${location.state} ${location.zipcode}</div>` +
                  `<div class="space-x-2 font-normal"><a href="tel://${location.phone}" title="Call ${location.name} store" class="underline">${location.phone}</a><span>|</span><span>${location.distance.toFixed(1)} mi.</span></div>` +
                  `</div>` +
                  `<div class="hidden lg:block">` +
                  `<div class="lead-h4">${location.closing_time}</div>` +
                  `<div class="space-x-2 text-18 font-normal">` +
                  `<a href="${location.details.url}" class="underline">Details</a><span>|</span><a href="https://www.google.com/maps/dir//${location.address} ${location.city}, ${location.state} ${location.zipcode}" class="underline" target="_blank">Get Directions</a>` +
                  `</div>` +
                  `</div>` +
                  `<div class="text-right w-1/4">` +
                  `<a href="/preferences/stock_locations" data- data-disable-with="<span class='spinner-sm pr-2 inline-block'></span>" data-method="PATCH" data-remote="true" class="btn btn-white js-setstore" data-params="stock_location[store_id]=${location.id}">${storeText}</a>` +
                  `</div>` +
                  `</div>`
                },
                marker(location) {
                return `<div class="p-4">${location.name}</div>`
              },
              storeinfo(location) {
                var storeText = location.details.preferred_location ? 'Unselect Store' : 'Set as Store'
                return `<div class="flex justify-between flex-col h-full"><div class="font-18 p-2 md:p-3 h-full">` +
                  `<h2 class="lead-h2 mb-2">${location.name} Details</h2>` +
                  `<div class="flex space-x-3">` +
                  `<div class="w-1/2 text-18">` +
                  `<div class="mb-2">` +
                  `<p class="font-medium mb-2 underline">Phone:</p>` +
                  `<a href="tel://${location.phone}" title="Call ${location.name} store" class="underline">${location.phone}</a><span>` +
                  `</div>` +
                  `<div class="text-18">` +
                  `<p class="font-medium mb-2 underline">Address:</p>` +
                  `<div class="">${location.address}</div>` +
                  `<div class="">${location.city}, ${location.state} ${location.zipcode}</div>` +
                  `</div>` +
                  `</div>` +
                  `<div class="">` +
                  `<p class="font-medium mb-2 underline">Store Hours:</p>` +
                  `<div class="text-18">${location.details.hours}</div>` +
                  `</div>` +
                  `</div>` +
                  `</div>` +
                  `<div>` +
                  `<div class="border-t border-gray-100 w-full p-2 md:p-3 flex justify-between">` +
                  `<button class="js-returntosidebar btn btn-white"><span class="bts bt-arrow-left mr-2"></span> Return</button>` +
                  `<a href="/preferences/stock_locations.js" data-disable-with="<span class='spinner-sm pr-2 inline-block'></span>" data-method="PATCH" data-remote="true" class="btn btn-white js-setstore" data-params="stock_location[store_id]=${location.id}">${storeText}</a>` + `</div>` +
                  `</div>` +
                  `</div>`
              },
              empty() {
                return `<p class="text-primary">No Results Found</p>`
              }
            }
          })

        })
      }

    },
    destroyStoreLocator(e) {
      App.vendor.store_locator.destroy()
    }
  });
};