import {
  Controller
} from "stimulus"

export default class extends Controller {
  connect() {
    this.sidebarModal = document.getElementById('sidebar-navigation')
    this.promotionModal = document.querySelector('#promotionModal')
  }

  showPromotionModal(e){
    this.promotionModal.modal.show()
  }

  showSidebar(e) {
    this.sidebarModal.modal.show()
  }
  hideSidebar() {
    this.sidebarModal.modal.hide()
  }
  showLoader() {
    this.sidebarModal.modal.showLoader()
  }
  hideLoader() {
    this.sidebarModal.modal.hideLoader()
  }
  disconnect() {
  }
}
