import {
  Controller
} from "stimulus"
import {
  Swiper
} from "../mixins/swiper";
export default class extends Controller {
  static targets = ["mobile", "mobileText"]
  connect() {
    var context = this
    import("headroom.js").then(function (Headroom) {
      App.utils.menu.headroom = new Headroom.default(context.element, {
        tolerance: 5,
        offset: 200
      })
      App.utils.menu.headroom.init();
    });
    Swiper(this)
  }

  disconnect() {
    App.utils.menu.headroom.destroy();
  }

  toggleMobile() {
    this.mobileTarget.classList.toggle('hidden')

    if(this.mobileTarget.classList.contains('hidden')){
      document.body.classList.remove("overflow-hidden");
      this.destroySwiper()
      this.mobileTextTarget.innerHTML = 'Menu'
    }else{
      document.body.classList.add("overflow-hidden");
      this.loadSwiper()
      this.mobileTextTarget.innerHTML = 'Close'
    }
  }
}