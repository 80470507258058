import {
  Controller
} from "stimulus"
import {
  lazyLoad
} from "./mixins/lazyload";

export default class extends Controller {
  connect() {
    lazyLoad(this)
    this.loadLazy()
  }

  disconnect() {

  }

}
