import {
  Controller
} from "stimulus"

import asyncLoader from 'async-loader-cdn';
const list = [{
  global: 'StampedFn',
  name: 'stamped',
  version: '',
  files: ['https://cdn-stamped-io.azureedge.net/files/widget.min.js'],
}];
export default class extends Controller {

  connect() {
    document.addEventListener("turbo:load", function () {
      document.body.addEventListener("ajax:beforeSend", (event) => {
        const [data, status, xhr] = event.detail;
        App.store.xhr.xhrPool.push(data);
      });

      document.body.addEventListener("ajax:complete", (event) => {
        const [data, status, xhr] = event.detail;
        App.store.xhr.xhrPool = App.store.xhr.xhrPool.filter(function (x) {
          return x != data
        });
      });
    });

    App.utils.abort_ajax = function () {
      App.store.xhr.xhrPool.forEach(xhr => {
        xhr.abort();
      });
    };
    
    this.loadSentry()
    this.loadStamped()
    
  }


    loadSentry() {
      if (typeof Sentry !== 'undefined' && window.SentryLoaded) { return }

      import('@sentry/browser').then((Sentry) => {
        Sentry.init({
          dsn: 'https://0df10a9cecf8c39471ad171668155a9d@sentry.southwestboulder.com/3',
  
          // This sets the sample rate to be 10%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          replaysSessionSampleRate: 0.1,
  
          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: 1.0,
  
          integrations: [
            new Sentry.Replay({
              // Additional SDK configuration goes in here, for example:
              maskAllText: false,
              blockAllMedia: false,
            }),
          ],
        });
        window.SentryLoaded = true
      })
    }

    initStamped(StampedFn) {
      StampedFn.init({
        apiKey: App.configuration.stamped.apiKey,
        storeUrl: App.configuration.stamped.storeUrl
      });
    }

    loadStamped() {
      if (typeof StampedFn !== 'undefined') {
        StampedFn.reloadUGC()
        this.initStamped(StampedFn)
      } else {
        if(!document.querySelector('.product')) {
          return
        }
        const stampedLoader = new asyncLoader(list)
        stampedLoader.load('stamped').then((StampedFn) => {
          this.initStamped(StampedFn)
        })
      }
    }



  disconnect() {

  }
}
