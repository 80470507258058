import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var announcement_data = JSON.parse(this.element.getAttribute('data-announcements'));

    if (window.refreshIntervalId) {
      clearInterval(window.refreshIntervalId);
    }
    var refreshIntervalId = null
    if (announcement_data.length > 0) {

      var textCount = 0;
      // initialize first announcement
      changeText()
      this.element.classList.remove('hidden');

      function changeText() {
        document.getElementsByClassName('announcement')[0].style.opacity = '1';
        var content = document.getElementsByClassName('announcement-content')[0]
        content.innerHTML =
          '<span class="announcement-text" id="announcementText" nonce="inline" tabindex="-1" class style="display: inline-block;" data-announcement-title="' +
          announcement_data[textCount].title + '" data-announcement-id="' + announcement_data[textCount].id +
          '">' + announcement_data[textCount].content + '</span>';
        var links = content.querySelectorAll('a');
        if (links.length) {
          links.forEach(li => {
            li.setAttribute('tabIndex', '-1');
          })

        }
        textCount < announcement_data.length - 1 ? textCount++ : textCount = 0;
      }
      if (announcement_data.length > 1) {
        window.refreshIntervalId = setInterval(changeText, 7000);
      }
    }
  }
  disconnect() {
    clearInterval(window.refreshIntervalId)
  }
}