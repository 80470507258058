import {
  Controller
} from "stimulus"
import {
  photoSwipe
} from "./mixins/photoswipe";
export default class extends Controller {
  static targets = ["mainImageGallery", "defaultImageBackground", "defaultImage", 'defaultImageLink', 'galleryImage', 'galleryImageLink']

  connect() {
    photoSwipe(this)
    this.loadPhotoswipe()
  }

  updateDefaultImage(e) {
    var index = e.currentTarget.getAttribute('data-index')
    if (!e.detail.open_gallery) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.mainImageGalleryTarget.swiper.slideTo(index)
  }
  
  imageZoom(e) {
    var zoomer = e.currentTarget;
    var offsetX = e.offsetX ? e.offsetX : e.touches[0].pageX
    var offsetY = e.offsetY ? e.offsetY : e.touches[0].pageX
    var x = offsetX / zoomer.offsetWidth * 100
    var y = offsetY / zoomer.offsetHeight * 100
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
  }
  
  showGallery(e) {
    e.preventDefault();
    if (e.currentTarget.getAttribute('data-open-gallery') === 'true') {
      var index = e.currentTarget.getAttribute('data-index')
      document.querySelectorAll('.image-gallery a')[index].dispatchEvent(new CustomEvent('click', {
        bubbles: true,
        detail: {
          open_gallery: true
        }
      }));
    }
  }
  disconnect() {
  }
}