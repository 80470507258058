import {
  Controller
} from "stimulus"
export default class extends Controller {
  static values = {
    id: String,
    siteKey: String,
    action: String
  }
  connect() {
    setTimeout(function () {
      this.loadRecaptcha();
    }.bind(this), 1500);
  }
  disconnect() {
    grecaptcha.reset(document.grecaptchaHandle)
  }
  loadRecaptcha() {
    let self = this;
    document.querySelectorAll('.grecaptcha-badge').forEach(element => {
      document.grecaptchaHandle = grecaptcha.render(element, {
        'sitekey': `${self.siteKeyValue}`,
        'badge': 'bottomright', // must be inline
        'size': 'invisible' // must be invisible
      });

      grecaptcha.ready(function () {
        grecaptcha.reset(document.grecaptchaHandle)
        grecaptcha.execute(document.grecaptchaHandle, {
          action: `${self.actionValue}`
        }).then(function (token) {
          document.getElementById(`${self.idValue}`).value = token;
        });
      });
    })
  }
}
