import {
  Controller
} from "stimulus"
import {
  storeLocator
} from "../mixins/store_locator";

export default class extends Controller {
  connect() {

  }
  initializeLocator(){
    storeLocator(this)
    this.loadStoreLocator()
  }
  disconnect() {
    if(google){
      google = null
    }
  }
}