import {
  Controller
} from "stimulus"

export default class extends Controller {
  connect() {
    this.addEventListenerStamped(document, 'stamped:badges:loaded', this.stampedStarOverride);
  }
  addEventListenerStamped(el, eventName, handler) {
    if (el.addEventListener) {
      el.addEventListener(eventName, handler);
    } else {
      el.attachEvent('on' + eventName, function () {
        handler.call(el);
      });
    }
  }
  stampedStarOverride(e) {
    let stamped_stars = document.querySelectorAll('.stamped-badge-caption')
    if (stamped_stars) {
      stamped_stars.forEach(function (el) {
        el.classList.remove('hidden')
      })
    }
    let stamped_zero_stars = document.querySelectorAll('.stamped-badge-caption[data-rating="0.0"]')

    if (stamped_zero_stars) {
      stamped_zero_stars.forEach(function (el) {
        el.parentElement.remove()
      })
    }
    
  }


  disconnect() {

  }
}