
import {
  Controller
} from "stimulus"
import A11yDialog from 'a11y-dialog'
import Cookies from "js-cookie"

export default class extends Controller {
  static targets = ["header", "loader", "content", "title", "container", "contentContainer"]
  static values = {
    remote: Boolean,
    cookie: String,
    route: String,
    classList: String,
    intitiallyVisible: Boolean,
    timeout: Number,
    bouncePrevention: Boolean
  }
  connect() {
    this.element[this.identifier] = this;
    this.dialog = new A11yDialog(this.element);
    const self = this;
    App.vendor.modals.push(this);
    this.showHeader();
    const seen = this.hasCookieValue && Cookies.get(this.cookieValue) ? true : false;


    this.element.addEventListener("show", function (event) {
      const target = event.target;
      document.body.classList.add("overflow-hidden");
      target.classList.add("is-shown");
      target.classList.remove("is-hidden");
    });

    this.element.addEventListener("hide", function (event) {
      const target = event.target;
      document.body.classList.remove("overflow-hidden");
      target.classList.remove("is-shown");
      target.classList.add("is-hidden");
      if (self.remoteValue) {
        self.showLoader();
      }
    });
    if (!seen && (this.hasRouteValue && (window.location.pathname === this.routeValue || this.routeValue === '/*'))) {
      if (this.bouncePreventionValue) {
        document.addEventListener(
          "mouseleave",
          function () {
            this.dialog.show();
            Cookies.set(this.cookieValue, "seen", {
              expires: 30,
            });
          }.bind(this)
        , { once: true });
      } else {
        setTimeout(
          function () {
            if (window.location.hash) {
              if (
                window.location.hash == `#${this.element.id}`
                
              ) {
                this.dialog.show();
                Cookies.set(this.cookieValue, "seen", { expires: 30 });
              }
            }
            if(this.intitiallyVisibleValue){
              this.dialog.show();
              Cookies.set(this.cookieValue, "seen", { expires: 30 });
            }
          }.bind(this),
          this.timeoutValue
        );

      }
    }
  }
  disconnect() {
    if (this.dialog) {
      this.clearContent()
      const self = this
      App.vendor.modals = App.vendor.modals.filter(modal => modal.element.id != self.element.id)
    }
  }


  show(){
    this.dialog.show()
  }
  
  refresh(){
    this.dialog.destroy();
    this.dialog.create();
  }

  close(){
    this.dialog.hide()
  }

  updateContentClass(classes){
    if (this.hasContentTarget) {
      this.contentTarget.classList = classes
    }
  }

  hideHeader(){
    if (this.hasHeaderTarget) {
      this.headerTarget.classList.add('hidden')
    }
  }

  showHeader(){
    if (this.hasHeaderTarget) {
      this.headerTarget.classList.remove('hidden')
    }
  }


  hideLoader(){
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.add('hidden')
    }
  }
  showLoader(){
    if (this.hasLoaderTarget) {
      this.clearContent()
      this.clearTitle()
      this.loaderTarget.classList.remove('hidden')
    }
  }
  clearContent(){
    if(this.hasContentTarget){
      this.contentTarget.innerHTML = null;
    }
  }
  clearTitle(){
    if(this.hasTitleTarget){
      this.titleTarget.innerHTML = null;
    }
  }
  setContentContainerClass(classes){
    if (this.hasContentContainerTarget) {
      this.contentContainerTarget.classList = null
      classes.forEach(function(clazz){
        this.contentContainerTarget.classList.add(clazz);
      }.bind(this))
    }
  }
  setTitle(title){
    if (this.hasTitleTarget) {
      this.titleTarget.innerHTML = title;
    }
  }
}
