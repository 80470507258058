import { Controller } from "stimulus";
import imagesLoaded from "imagesloaded";

export default class extends Controller {
  static targets = ["loader", "content"];

  connect() {
    this.cells = document.querySelectorAll(".masonry-cell");
    this.grid;

    import("masonry-layout").then((Masonry) => {
      // using the library masonry-layout create grid layout loop through each item and add animation
      this.grid = new Masonry.default(this.contentTarget, {
        itemSelector: ".masonry-cell",
        transitionDuration: ".15s",        
      });
    });


      // using the library masonry-layout create grid layout loop through each item and add animation
      this.imagesLoaded = new imagesLoaded(
        this.contentTarget,
        function () {
          this.loaderTarget.classList.add("hidden");
          this.contentTarget.classList.remove("hidden");
          this.animateItems();
        }.bind(this)
      );
  }
  animateItems() {
    var items = this.cells;

    items.forEach(function (item, index) {
      setTimeout(
        function () {
          item.classList.add("fade-in");
        }.bind(this),
        index * 150
        ); // Delay animation for each item
        this.grid.layout();
    }.bind(this));
  }
  disconnect() {
    var grid = document.querySelector(".masonry-root");
  }
}
