import {
  Controller
} from "stimulus"
export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.contentTarget.querySelectorAll('a').forEach(function (el) {
      el.setAttribute('target', '_BLANK')
    })
  }

  disconnect() {}

  closeModal(e) {
    this.containerTarget.remove()
  }
}