export const Sharer = controller => {
  Object.assign(controller, {
    loadSharer(e) {
      if (document.getElementsByClassName('sharer').length) {
        import('sharer.js').then((Sharer) => {
          App.vendor.sharer = Sharer;
        });
      }
    },
    destroyModal(e) {}
  });
};
