import {
  Controller
} from "stimulus"
import cardy from "cardy";
import cardValidator from "card-validator";

export default class extends Controller {
  static targets = ["submit", "shipAddress", "billAddress", "card", "expiration", 'useShippingAddress']
  connect() {
    document.getElementById('payment_form_button').disabled = true
    this.toggleShipAddress()
  }

  disconnect() {
    document.getElementById('cvv_link').removeEventListener('click');

  }

  validateCard(validity, target, that, cardValidatorResult) {
    if (validity.isValid) {
      document.querySelector('.card-number-error').classList.add('hidden')
      target.classList.remove('border-primary')
      document.getElementById('payment_form_button').disabled = false
      var date = new Date(`${cardValidatorResult.month}/01/${cardValidatorResult.year}`)
      that.expirationTarget.value = `${date.getMonth() + 1}/${date.getFullYear()}`
    
    } else {
      document.querySelector('.card-number-error').classList.remove('hidden')
      target.classList.add('border-primary')
      document.getElementById('payment_form_button').disabled = true
    }
  }
  validateExpiration(validity, target, that, cardValidatorResult) {
    if (validity.isValid) {
      document.querySelector('.expiration-number-error').classList.add('hidden')
      target.classList.remove('border-primary')
      document.getElementById('payment_form_button').disabled = false
      console.log(cardValidatorResult)
      var date = new Date(`${cardValidatorResult.month}/01/${cardValidatorResult.year}`)
      that.expirationTarget.value =  `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`
    } else {
      that.expirationTarget.value = null;
      document.querySelector('.expiration-number-error').classList.remove('hidden')
      target.classList.add('border-primary')
      document.getElementById('payment_form_button').disabled = true
    }
  }

  toggleShipAddress(){
    if(this.hasUseShippingAddressTarget && this.useShippingAddressTarget.checked){
      this.shipAddressTarget.classList.remove('hidden')
      this.billAddressTarget.classList.add('hidden')
      this.billAddressTarget.getElementsByTagName('input').forEach(element => {
        element.noValidate = true
        element.required = false
      })
      this.billAddressTarget.getElementsByTagName('select').forEach(element => {
        element.noValidate = true
        element.required = false
      })
    }else{
      this.shipAddressTarget.classList.add('hidden')
      this.billAddressTarget.classList.remove('hidden')
      this.billAddressTarget.getElementsByTagName('input').forEach(element => {
        element.required = true
        element.noValidate = false
        if(element.id == 'order_bill_address_attributes_address2'){
          element.required = false
        }
      })
      this.billAddressTarget.getElementsByTagName('select').forEach(element => {
        element.required = true
        element.noValidate = false
      })
    }
  }

  maskHandler(e) {
    var target = e.target;
    if (target.matches('#card_number')) {
      target.value = cardy.number.mask(target.value)
    }
    if (target.matches('#card_expiry')) {
      target.value = cardy.expiration.mask(target.value)
    }
    if (target.matches('#card_code')) {
      target.value = cardy.code.mask(target.value)
    }
  }

  cardHandler(e) {
    var target = e.target;
    var that = this
    if (target.matches('#card_number')) {
      clearTimeout(cardTimer)
      var cardTimer = setTimeout(function () {
        that.cardTargets.forEach(element => {
            element.classList.add('hidden')
        });
        document.querySelector('.card-number-error').classList.add('hidden')
        target.classList.remove('border-primary')
        var validity = cardy.number.validate(target.value)
        const cardValidatorResult = cardValidator.number(target.value);
        if (cardValidatorResult.card){
          that.cardTargets.forEach(element => {
            if (cardValidatorResult.card.type === element.getAttribute('data-card-type')) {
              element.classList.remove('hidden')
            } else {
              element.classList.add('hidden')
            }
          });
        }

        if (cardValidatorResult.card && cardValidatorResult.card.lengths.indexOf(target.value.replace(/\s/g, '').length) >= 0) {
          that.validateCard(validity, target, that, cardValidatorResult)
        }
      }.bind(this), 800)
    }
    if (target.matches('#card_expiry')) {
      clearTimeout(cardTimer)
      var cardTimer = setTimeout(function () {
        document.querySelector('.expiration-number-error').classList.add('hidden')
        target.classList.remove('border-primary')
        if (target.value.length > 0) {
          const cardValidatorResult = cardValidator.expirationDate(target.value);
          that.validateExpiration(cardValidatorResult, target, that, cardValidatorResult)
        }
      }.bind(this), 800)
    }
  }

validateDate(date) {
  var validity = {
    isValid: true
  }
  // Check if the date is in the correct format
  if (!/\d{2}-\d{2}/.test(date)) {
    validity.isValid = false;
  }

  // Split the date into its components
  var month = date.substring(0, 2);
  var year = date.substring(3, 5);

  // Check if the month is valid
  if (month < 1 || month > 12) {
    validity.isValid = false;
  }

  // Check if the year is valid
  if (year < 1900 || year > 2100) {
    validity.isValid = false;
  }

  // The date is valid
  return validity
}



}


