export const Swiper = controller => {
  Object.assign(controller, {
    loadSwiper(e) {
      var swiper;
      import('swiper/bundle').then((Swiper) => {
        this.swiper = Swiper.default;
        document.querySelectorAll('.swiper-container:not([data-initialized="true"])').forEach((container, index) => {
          var timestamp = Date.now()
          container.classList.add('s' + timestamp);
          if (container.querySelector('.swiper-pagination')) {
            container.querySelector('.swiper-pagination').classList.add('sp' + timestamp)
          }
          if (container.querySelector('.swiper-button')) {
            container.querySelector('.swiper-button.prev').classList.add('sbp' + timestamp);
            container.querySelector('.swiper-button.next').classList.add('sbn' + timestamp);
          }

          var attr_data = JSON.parse(container.getAttribute('data-swiper'));
          var common_data = {
            pagination: {
              el: ".sp" + timestamp,
              clickable: true
            },
            navigation: {
              prevEl: '.sbp' + timestamp,
              nextEl: '.sbn' + timestamp,
            },
            a11y: {
              slideRole: ''
            },
            preloadImages: false,
            grabCursor: true,
            observer: true,
            observeParents: true,
            on: {
              init: function () {
                import('lazyload').then((lazyload) => {
                  App.vendor.lazy.sliderimg = new lazyload.default(container.querySelectorAll("img"));
                })
              },
            }
            // "lazy": true
          }
          for (var attrname in common_data) {
            attr_data[attrname] = common_data[attrname]
          }
          swiper = new this.swiper('.s' + timestamp, attr_data);
          swiper.container = container
          // if (attr_data.autoplay) {
          //   container.addEventListener('mouseleave', function () {
          //     swiper.autoplay.start();
          //   });
          //   container.addEventListener('mouseenter', function () {
          //     swiper.autoplay.stop();
          //   });

          // }
          if (container.getAttribute('data-change-image') === 'true'){
            swiper.on('slideChange', function (e) {
              var el = document.querySelectorAll('.image-gallery a')[e.activeIndex]
              document.querySelector('.main-gallery-image img').src = el.href;
              document.querySelector('.main-gallery-image').setAttribute('data-index', e.activeIndex);
            });
          }
          App.vendor.swiper.elements.push(swiper);
          container.setAttribute('data-initialized', true)
          
        });
      })
      return swiper
    },
    destroySwiper(e) {

      App.vendor.swiper.elements.forEach(function(el){
        el.container.setAttribute('data-initialized', false)
        el.destroy(true, false)
      })
      App.vendor.swiper.elements = []
    }
  });
};

