export const Houdini = controller => {
  Object.assign(controller, {
    loadHoudini(e) {
      import('houdinijs').then((Houdini) => {
        App.vendor.Houdini = Houdini
        document.querySelectorAll('[data-houdini-settings]').forEach((e) => {
          e.setAttribute('initialized', true)
          const settings = JSON.parse(e.getAttribute('data-houdini-settings'))
          settings.uniq_id = `${settings.groupName}_${(new Date()).getTime().toString()}`
          document.querySelectorAll(`[data-houdini-group="${settings.groupName}"]`).forEach(function (e) {
            e.setAttribute('data-houdini-uniq-group', settings.uniq_id)
          })
          var houdini = new App.vendor.Houdini.default(`[data-houdini-uniq-group="${settings.uniq_id}"]`, settings)
          houdini.settings = settings
          App.vendor.houdinis.push(houdini);
        })
      })
    },
    destroyHoudini(e){
      if (App.vendor.houdinis && App.vendor.houdinis.length) {
        App.vendor.houdinis.forEach(function (h) {
          h.destroy()
        })
      }
      App.vendor.houdinis = [];
    }
  });
};