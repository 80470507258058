import {
  Controller
} from "stimulus"
import {
  HyperForm
} from "./mixins/form_validator";
import {
  FormHelper
} from "./mixins/form_helper";
import Swal from "sweetalert2/dist/sweetalert2.js"

export default class extends Controller {
  static targets = ["successTemplate", "errorTemplate", "form", "submit", 'messages', 'telephone', 'file', 'fileCount']
  static values = {
    flash: Object
  }
  timeout
  path = require('path');
  connect() {
    HyperForm(this)
    FormHelper(this)
    this.loadHyperForm()
    if (this.hasFlashValue && this.hasMessagesTarget) {
      let message = this.handleMessages(Object.keys(this.flashValue)[0], Object.values(this.flashValue)[0])
      Swal.fire({
        target: this.messagesTarget,
        html: message.value,
        toast: false,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        customClass: {
          container: message.classList,
          }
      })
    }
  }

  disconnect() {
    
  }

  updateFileCount(e){
    if (this.hasFileTarget) {
      if (this.hasFilCountTarget) {
        this.fileCountTarget.innerHTML = null
      }
      if (this.hasFileCountTarget) {
        var abbreviate = e.target.files.length > 1 ? 's' : ''
        this.fileCountTarget.innerHTML = e.target.files.length + ` file${abbreviate} selected`
      }
    }
  }

  submit(){
    clearTimeout(this.timeout)
    this.timeout = setTimeout(function(){
      this.element.requestSubmit()
    }.bind(this), 400)
  }

  handleMessages(type, value){
    this.message = {value: value, classList: ''}
    switch (type) {
      case 'error':
        this.message.classList = 'border border-primary rounded-md p-3 mb-4 text-primary text-15 font-normal'
        break;
      case 'success':
        this.message.classList = 'border border-green rounded-md p-3 mb-4 text-green text-15 font-normal'
        break;
      default:
        break;
    }
    return this.message
  }



  isNumericInput(event){
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey(event){
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };

  enforceFormat(event){
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
      event.preventDefault();
    }
  };

  formatToPhone(e){
    if (this.isModifierKey(e)) {
      return;
    }

    // I am lazy and don't like to type things more than once
    const input = e.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      e.target.value = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      e.target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      e.target.value = `(${zip}`;
    }
  };


}
