import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["calculator", 'optionValue', 'depth', 'depthContainer', 'squareFeet', 'taxon']
  static values = {
    variants: Array
  }


  connect() {
    if(this.hasOptionValueTarget){
      this.optionValueTarget.dispatchEvent(new Event("change"))
    }
  }

  disconnect() {}

  getOptions(e){
    this.calculatorTarget.classList.add('opacity-75', 'spinner', 'spinner-md', 'spinner-primary')
    var request = {
      taxon: e.target.value,
      depth: this.depthTarget.value,
      square_feet: this.squareFeetTarget.value
    }
    App.vendor.rails.ajax({
      type: "PUT",
      url: `/resources/coverage-calculator?timestamp=${Date.now()}`,
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Accept', 'application/vnd.turbo.stream.html')
        xhr.setRequestHeader('Content-Type', 'application/json')
        options.data = JSON.stringify(request)
        return true
      },
      success: function (response) {
        this.calculatorTarget.innerHTML = response
      }.bind(this)
    });
  }

  updateDepth(e){
    if (e.target.options[e.target.selectedIndex].getAttribute('data-depth-calculable') == 'false'){
      this.depthTarget.value = 2
      if(this.hasDepthContainerTarget){
        this.depthContainerTarget.classList.add('hidden')
      }
    }else{
      this.depthContainerTarget.classList.remove('hidden')
    }
  }

}