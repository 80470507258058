import {
  Controller
} from "stimulus"

export default class extends Controller {

  static targets = ["emailUpdate", "newsletterUpdate", "customerTypeUpdate"]
  static values = {
    url: String
  }
  connect() {
    const dynamicModal = document.querySelector('#dynamicModal')
  }

  disconnect() {

  }

  openModal(title, container_class){
    dynamicModal.modal.show()
    dynamicModal.modal.showLoader()
    dynamicModal.modal.setTitle(title)
    dynamicModal.modal.setContentContainerClass(container_class)
  }

  editContactInformation(){
    this.openModal('Edit Customer Details', ['p-1', 'md:p-3'])
    App.vendor.rails.ajax({
      type: "GET",
      url: `/account/edit_customer_details`,
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Accept', 'application/vnd.turbo.stream.html')
        xhr.setRequestHeader('Content-Type', 'application/vnd.turbo.stream.html')
        return true
      },
      success: function (response) {
        dynamicModal.modal.hideLoader()
        dynamicModal.modal.contentTarget.innerHTML = response
      }
    });
  }

  editPassword(){
    this.openModal('Edit Password', ['p-1', 'md:p-3'])
    App.vendor.rails.ajax({
      type: "GET",
      url: `/account/edit_password`,
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Accept', 'application/vnd.turbo.stream.html')
        xhr.setRequestHeader('Content-Type', 'application/vnd.turbo.stream.html')
        return true
      },
      success: function (response) {
        dynamicModal.modal.hideLoader()
        dynamicModal.modal.contentTarget.innerHTML = response
      }
    });
  }

}