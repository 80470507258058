import {
  Controller
} from "stimulus"
export default class extends Controller {
  connect() {
  }
  openDialog(e){
    e.preventDefault();
    
  }
}