import {
  App
} from "./application"
if ('serviceWorker' in navigator) {

  function onInstall(event) {
    console.log('[Serviceworker]', "Service Worker Installing!", event);
  }

  function onActivate(event) {
    console.log('[Serviceworker]', "Service Worker Activating!", event);

  }

  // Borrowed from https://github.com/TalAter/UpUp
  function onFetch(event) {
    event.respondWith(
      // try to return untouched request from network first
      fetch(event.request).catch(function () {
        // if it fails, try to return request from the cache
        return caches.match(event.request).then(function (response) {
          if (response) {
            return response;
          }
          // if not found in cache, return default offline content for navigate requests
          if (event.request.mode === 'navigate' ||
            (event.request.method === 'GET' && event.request.headers.get('accept').includes('text/html'))) {
            console.log('[Serviceworker]', "Service Worker Fetching offline content", event);
            return caches.match('/offline.html');
          }
        })
      })
    );
  }
  
  navigator.serviceWorker.register('/service-worker.js', {
    scope: "/"
  }).then(function (registration) {
      console.log('[ServiceWorker Client]', 'registered');
      self.addEventListener('install', onInstall);
      self.addEventListener('activate', onActivate);
      self.addEventListener('fetch', onFetch);
  }, function (err) {
    console.log('[ServiceWorker Client]', 'registration failed: ', err);
  });

}