import { Controller } from "stimulus"
export default class extends Controller {

  connect() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.storeGeolocationFor.bind(this), this.storeGeolocationFor.bind(this))
    } else {
      this.storeGeolocationFor(undefined)
    }
  }

  requestFor(user_lat, user_long) {
    return {
      geolocation: {
        user_lat: user_lat,
        user_long: user_long
      }
    }
  }

  storeGeolocationFor(position) {
    if (position instanceof GeolocationPositionError || typeof position === 'undefined') {
      var request = this.requestFor(null, null)
    } else {
      var request = this.requestFor(position.coords.latitude, position.coords.longitude)
    }

    App.vendor.rails.ajax({
      type: "POST",
      url: "/geolocation",
      headers: {
        'X-CSRF-Token': App.vendor.rails.csrfToken(),
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify(request)
        return true
      },
      success: function (response) {
        this.element.setAttribute('data-initialized', true)
      }.bind(this)
    });
  }
}