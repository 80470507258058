export const FormHelper = controller => {
  Object.assign(controller, {
    handleSubmit(e) {  
      if (e.detail.formSubmission.result.success){
        if(this.hasSuccessTemplateTarget && this.hasFormTarget){
          this.formTarget.classList.add('hidden')
          this.successTemplateTarget.classList.remove('hidden')
          
        }
      }else{
        if (this.hasErrorTemplateTarget && this.hasFormTarget) {
          this.formTarget.classList.add('hidden')
          this.errorTemplateTarget.classList.remove('hidden')
        }
      }

      document.querySelectorAll('button[type="submit"]').forEach(function(el){
            App.vendor.rails.enableElement(el)

      });
    },
    showMessage(e) {   
    },
  });
};