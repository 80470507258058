import {
  Controller
} from "stimulus"
export default class extends Controller {
  connect(){
    var self = this;
    const consent_cookie = this.getCookie("cf_consent");
    if (!consent_cookie) {
      setTimeout(
        function () {
          window.scrollTo(0, 0);
          this.element.modal.refresh();
          this.element.modal.show();
          document.body.classList.remove("overflow-hidden");
        }.bind(this),
        10
      );
      window.addEventListener("scroll", this.handleScroll.bind(this));
    }
  }
  handleScroll(){
    var h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
    var position = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
    if (position >= 50) {
      this.element.modal.close()
      this.acceptAll()
    }
  }
  getCookie(name) {
    const value = `; ${document.cookie}`
    return value?.split(`; ${name}=`)[1]?.split(";")[0]
  }

  disconnect(){
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }
  preferences(){
    this.element.modal.close()
    if(typeof zaraz === 'undefined') {return};
    zaraz.showConsentModal()
    zaraz.consent.setAllCheckboxes(true);

  }
  declineAll(){
    this.element.modal.close()
    if(typeof zaraz === 'undefined') {return};
    zaraz.consent.setAll(false)
    zaraz.consent.sendQueuedEvents();
  }
  acceptAll(){
    this.element.modal.close()
    if(typeof zaraz === 'undefined') {return};
    zaraz.consent.setAll(true)
    zaraz.consent.sendQueuedEvents();
  }
}